
import { createContext, useContext } from 'react';

export const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

// export const LevelContext = createContext();

// export function useLevel(){
//   return useContext(LevelContext);
// }