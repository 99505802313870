// Will bagian ini attach image nya kan ada 2 tuh entar tolong di handle ya will biar masing masing beda fotonya kalo di upload
import { Button, Form, Image, Col, Row, Spinner } from "react-bootstrap";
import React, { Component, useState, useEffect } from "react";
import { useForm, ErrorMessage } from "react-hook-form";
import Kota from "../data/Data"
import AutoComplete from "./AutoComplete_Com"
import '../styles/Colour_Style.css'
import '../styles/Form_Style.css'
import { handleTextInput, handleNumberInput, condensingWhitespace } from "../logics/FormInput_Logic";
import { upload, submitSupir, editSupir } from "../logics/SubmitForm_Logic"
import { useAuth } from "../context/Auth_Context"
import { deleteGambar } from "../logics/DeleteData_Logic";
import { fetchKendaraan } from "../logics/FetchData_Logic";

function FormSupirFunCom(props) {
    const { register, handleSubmit, errors, setValue, getValues, setError, clearError } = useForm({
        mode: "onChange",
        validateCriteriaMode: "all",
        defaultValues: {
            nama: props.edit ? props.edit.nama : "",
            no_hp: props.edit ? props.edit.no_hp : "",
            nik: props.edit ? props.edit.nik : "",
            sim: props.edit ? props.edit.no_sim : "",
            path_uri_nik: props.edit ? props.edit.path_uri_ktp : "",
            path_uri_sim: props.edit ? props.edit.path_uri_sim : "",
            id: props.edit ? props.edit._id : "",
            no_kendaraan: props.edit ? props.edit.idKendaraan._id : "",
            transportir: props.edit ? props.edit.idPengirim : "",
            state: props.edit ? "edit" : "input",
            //no_kendaraan:
        }
    });

    const [state, setState] = useState({
        image_nik: props.edit ? props.edit.path_uri_ktp : "assets/image.png",
        image_sim: props.edit ? props.edit.path_uri_sim : "assets/image.png",
        formDataNIK: null,
        formDataSIM: null,
        loading: false,
        pathNIK: null,
        pathSIM: null,
    });

    // const [kendaraan, setKendaraan] = useState({
    //     loading: false,
    //     data: null
    // })

    const { auth, setToken } = useAuth();
    const max = 2097152

    const validateImage = (value) => {
        return value[0] ? value[0].size <= max : getValues("state") === "edit";;
    }

    const inputImage = (event) => {
        let objFormData = new FormData();
        objFormData.append("image", event.target.files[0]);
        if (event.target.name === "image_nik") {
            setState({
                ...state,
                image_nik: URL.createObjectURL(event.target.files[0]),
                formDataNIK: objFormData,
                pathNIK: event.target.files[0].name
            })
        } else {
            setState({
                ...state,
                image_sim: URL.createObjectURL(event.target.files[0]),
                formDataSIM: objFormData,
                pathSIM: event.target.files[0].name
            })
        }
    }

    const handleChange = (event) => {
        let val = "";
        if (event.target.name === "no_hp" || event.target.name === "nik" || event.target.name === "sim") {
            val = handleNumberInput(event);
        }
        else {
            val = handleTextInput(event.target.name, event);
        }
        setValue(event.target.name, val)
    }

    const onSubmit = async value => {
        setState({
            ...state,
            loading: true
        })
        value.idPerusahaan = auth.idPerusahaan;
        let didUpload_1 = false;
        let didUpload_2 = false;
        let tahap_1 = true;
        let tahap_2 = true;
        let status = [];
        let message = [];
        let success = false;
        value = condensingWhitespace(value)

        if (value.image_nik.length === 0) value.path_uri_nik = getValues("path_uri_nik");
        else {
            let response_image = await upload(auth.token, state.formDataNIK);
            if (response_image[0] && response_image[0].status === 200) {
                didUpload_1 = true;
                value.path_uri_nik = response_image[0].data[0].url
                response_image[0].token && setToken(response_image[0].token)
            }
            else if (response_image[0] && response_image[0].status === 402) {
                let path = "/";
                props.history.push({
                    pathname: path,
                    state: "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi."
                })
            }
            else {
                if (response_image[1]) {
                    response_image[1].error.map((item) => {
                        status = status.concat(1);
                        message = message.concat("Status: " + response_image[1].status + " - " + response_image[1].message + ". " + item.msg);
                    })
                } else {
                    status = status.concat(1);
                    message = message.concat("Status: " + response_image[0].status + " - " + response_image[0].message + ". " + response_image[0].error[0].msg);
                }
                tahap_1 = false;
            }
        }

        if (tahap_1) {
            if (value.image_sim.length === 0) value.path_uri_sim = getValues("path_uri_sim");
            else {
                let response_image = await upload(auth.token, state.formDataSIM);
                if (response_image[0] && response_image[0].status === 200) {
                    didUpload_2 = true;
                    value.path_uri_sim = response_image[0].data[0].url
                    response_image[0].token && setToken(response_image[0].token)
                }
                else if (response_image[0] && response_image[0].status === 402) {
                    let path = "/";
                    props.history.push({
                        pathname: path,
                        state: "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi."
                    })
                }
                else {
                    if (response_image[1]) {
                        response_image[1].error.map((item) => {
                            status = status.concat(1);
                            message = message.concat("Status: " + response_image[1].status + " - " + response_image[1].message + ". " + item.msg);
                        })
                    } else {
                        status = status.concat(1);
                        message = message.concat("Status: " + response_image[0].status + " - " + response_image[0].message + ". " + response_image[0].error[0].msg);
                    }
                    tahap_2 = false;
                }
            }
        } else {
            let responseDel = didUpload_1 ? await deleteGambar(auth.token, value.path_uri_nik) : null;
            if (responseDel) {
                if (responseDel[0] && responseDel[0].status === 402) {
                    let path = "/";
                    props.history.push({
                        pathname: path,
                        state: "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi."
                    })
                } else if (responseDel[0] && responseDel[0].status === 200) {
                    responseDel[0].token && setToken(responseDel[0].token)
                } else {
                    if (responseDel[1]) {
                        responseDel[1].error.map((item) => {
                            status = status.concat(1);
                            message = message.concat("Status: " + responseDel[1].status + " - " + responseDel[1].message + ". " + item.msg);
                        })
                    } else {
                        status = status.concat(1);
                        message = message.concat("Status: " + responseDel[0].status + " - " + responseDel[0].message + ". " + responseDel[0].error[0].msg);
                    }
                }
            }
        }

        let response = null;
        if (tahap_1 && tahap_2) {
            response = getValues("state") === "edit" ? await editSupir(value, auth.token, getValues("id")) : await submitSupir(value, auth.token);
            if (response[0] && response[0].status === 200) {
                success = true;
                response[0].token && setToken(response[0].token)
            }
            else if (response[0] && response[0].status === 402) {
                let path = "/";
                props.history.push({
                    pathname: path,
                    state: "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi."
                })
            }
            else {
                if (response[1]) {
                    response[1].error.map((item) => {
                        status = status.concat(1);
                        message = message.concat("Status: " + response[1].status + " - " + response[1].message + ". " + item.msg);
                    })
                } else {
                    status = status.concat(1);
                    message = message.concat("Status: " + response[0].status + " - " + response[0].message + ". " + response[0].error[0].msg);
                }

                let responseDel = didUpload_1 ? await deleteGambar(auth.token, value.path_uri_nik) : null;
                if (responseDel) {
                    if (responseDel[0] && responseDel[0].status === 402) {
                        let path = "/";
                        props.history.push({
                            pathname: path,
                            state: "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi."
                        })
                    } else if (responseDel[0] && responseDel[0].status === 200) {
                        responseDel[0].token && setToken(responseDel[0].token)
                    } else {
                        if (responseDel[1]) {
                            responseDel[1].error.map((item) => {
                                status = status.concat(1);
                                message = message.concat("Status: " + responseDel[1].status + " - " + responseDel[1].message + ". " + item.msg);
                            })
                        } else {
                            status = status.concat(1);
                            message = message.concat("Status: " + responseDel[0].status + " - " + responseDel[0].message + ". " + responseDel[0].error[0].msg);
                        }
                    }
                }

                let responseDel_2 = didUpload_1 ? await deleteGambar(auth.token, value.path_uri_sim) : null;
                if (responseDel_2) {
                    if (responseDel_2[0] && responseDel_2[0].status === 402) {
                        let path = "/";
                        props.history.push({
                            pathname: path,
                            state: "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi."
                        })
                    } else if (responseDel_2[0] && responseDel_2[0].status === 200) {
                        responseDel_2[0].token && setToken(responseDel_2[0].token)
                    } else {
                        if (responseDel_2[1]) {
                            responseDel_2[1].error.map((item) => {
                                status = status.concat(1);
                                message = message.concat("Status: " + responseDel_2[1].status + " - " + responseDel_2[1].message + ". " + item.msg);
                            })
                        } else {
                            status = status.concat(1);
                            message = message.concat("Status: " + responseDel_2[0].status + " - " + responseDel_2[0].message + ". " + responseDel_2[0].error[0].msg);
                        }
                    }
                }
            }
        }
        setState({
            ...state,
            loading: false,
        })
        if (success) {
            props.showModal(getValues("state"), value.nama);
        } else {
            let idx = 0;
            status.map(function (item) {
                props.generateToast(item, message[idx]);
                idx = idx + 1;
            })
        }
    }
    return (
        <div className="container">
            {getValues("state") === "edit" && <h4>Sedang mengedit supir: {props.edit.nama}</h4>}
            <Form className="form" onSubmit={handleSubmit(onSubmit)}>
                <Form.Group controlId="formBasicNama" >
                    <Form.Label >Nama :</Form.Label>
                    <Form.Control
                        className={errors.nama && "invalid"}
                        name="nama"
                        type="text"
                        //value={this.state.name} 
                        ref={register({ required: "Input tidak boleh kosong" })}
                        onChange={handleChange} />
                    <ErrorMessage errors={errors} name="nama">
                        {({ message }) => <p className="error_info">{message}</p>}
                    </ErrorMessage>
                </Form.Group>
                <Form.Row>
                    <Form.Group as={Col} controlId="formBasicHP">
                        <Form.Label for="phone">No HP  :<em> (Opsional)</em></Form.Label>
                        <Form.Control
                            className={errors.no_hp && "invalid"}
                            type="tel"
                            name="no_hp"
                            //value={this.state.no_hp} 
                            ref={register({
                                // required: "Input tidak boleh kosong",
                                // pattern: { value: /^[+-]?\d+$/, message: "Format salah, contoh yang benar: 02112312" },
                                // maxLength: { value: 16, message: "Nomor terlalu panjang, maksimal 16 digit" },
                                // minLength: { value: 8, message: "Nomor terlalu pendek, minimal 8 digit" }
                            })}
                            onChange={handleChange} />
                        <ErrorMessage errors={errors} name="no_hp">
                            {({ message }) => <p className="error_info">{message}</p>}
                        </ErrorMessage>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formBasicNIK">
                        <Form.Label >No NIK  :</Form.Label>
                        <Form.Control
                            className={errors.nik && "invalid"}
                            name="nik"
                            type="text"
                            //value={this.state.nik} 
                            ref={register({
                                required: "Input tidak boleh kosong",
                                minLength: {
                                    value: 16,
                                    message: "Input tidak boleh lebih atau kurang dari 16 digit"
                                },
                                maxLength: {
                                    value: 16,
                                    message: "Input tidak boleh lebih atau kurang dari 16 digit"
                                }
                            })}
                            onChange={handleChange} />
                        <ErrorMessage errors={errors} name="nik">
                            {({ message }) => <p className="error_info">{message}</p>}
                        </ErrorMessage>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formBasicSIM">
                        <Form.Label >No SIM :</Form.Label>
                        <Form.Control
                            className={errors.sim && "invalid"}
                            type="text"
                            name="sim"
                            //value={this.state.username} 
                            ref={register({ 
                                required: "Input tidak boleh kosong",
                                minLength: {
                                    value: 10,
                                    message: "Input tidak boleh kurang dari 10 digit"
                                }
                            })}
                            onChange={handleChange} />
                        <ErrorMessage errors={errors} name="sim">
                            {({ message }) => <p className="error_info">{message}</p>}
                        </ErrorMessage>
                    </Form.Group>
                </Form.Row>
                <Form.Group>
                    <Form.Label >No Polisi Kendaraan  :</Form.Label>
                    <Form.Control
                        className={errors.no_kendaraan && "invalid"}
                        as="select"
                        name="no_kendaraan"
                        //disabled={kendaraan.loading}
                        //onClick={handleClick}
                        ref={register({ required: "Input tidak boleh kosong" })}
                    >
                    <option value="">Pilih No. Kendaraan</option>
                        {
                            props.kendaraan && props.kendaraan.map((item,key) => 
                                <option name="supir" value={item._id}>{item.no_polisi}</option>)
                        }
                        {
                            // kendaraanData && <option name="supir" value={kendaraanData.otherId}>LAINNYA</option>
                        }
                    </Form.Control>
                    <ErrorMessage errors={errors} name="no_kendaraan">
                        {({ message }) => <p className="error_info">{message}</p>}
                    </ErrorMessage>
                </Form.Group>
                <Form.Group>
                    <Form.Label >Transportir  :</Form.Label>
                    <Form.Control
                        className={errors.no_kendaraan && "invalid"}
                        as="select"
                        name="transportir"
                        //disabled={kendaraan.loading}
                        //onClick={handleClick}
                        ref={register({ required: "Input tidak boleh kosong" })}
                    >
                    <option value="">Pilih Transportir</option>
                        {
                            props.transportir && props.transportir.map((item,key) => 
                                <option name="pengirim" value={item._id}>{item.nama}</option>)
                        }
                        {
                            // kendaraanData && <option name="supir" value={kendaraanData.otherId}>LAINNYA</option>
                        }
                    </Form.Control>
                    <ErrorMessage errors={errors} name="no_kendaraan">
                        {({ message }) => <p className="error_info">{message}</p>}
                    </ErrorMessage>
                </Form.Group>
                <div className="container-image">
                    <div className="form-image">
                        {getValues("state") === "edit" &&
                            <em> (Opsional)</em>
                        }
                        <div className="input-group">
                            <div className="custom-file">                               
                                <input
                                    name="image_nik"
                                    accept="image/*"
                                    type="file"
                                    className="custom-file-input"
                                    ref={getValues("state") !== "edit" ?
                                        register({
                                            required: true,
                                            validate: value => validateImage(value) || 'Gambar yang anda masukkan melebihi batas maksimal (2MB)'
                                        }) : register({
                                            validate: value => validateImage(value) || 'Gambar yang anda masukkan melebihi batas maksimal (2MB)'
                                        })}
                                    onChange={inputImage}
                                />                               
                                <label
                                    className={(errors.image_nik || errors.imgSize_1) ? "custom-file-label invalid" : "custom-file-label"}
                                    htmlFor="inputGroupFile01"
                                >
                                    {state.pathNIK ? state.pathNIK : "Pilih Foto KTP"}
                                </label>
                            </div>
                        </div>
                        <ErrorMessage errors={errors} name="image_nik">
                            {({ message }) => <p className="error_info">{message}</p>}
                        </ErrorMessage>
                        <div className="btn-image">
                            <Image
                                src={state.image_nik}
                                style={{
                                    width: "13rem",
                                    height: "13rem",
                                    marginBottom: 15,
                                    alignSelf: "center"
                                }}
                            />
                            <p>
                                KTP
                            </p>
                        </div>
                    </div>
                    <div className="form-image">
                        {getValues("state") === "edit" &&
                            <em> (Opsional)</em>
                        }
                        <div className="input-group">
                            <div className="custom-file">
                                <input
                                    name="image_sim"
                                    accept="image/*"
                                    type="file"
                                    className="custom-file-input"
                                    ref={getValues("state") !== "edit" ?
                                        register({
                                            required: true,
                                            validate: value => validateImage(value) || 'Gambar yang anda masukkan melebihi batas maksimal (2MB)'
                                        }) : register({
                                            validate: value => validateImage(value) || 'Gambar yang anda masukkan melebihi batas maksimal (2MB)'
                                        })}
                                    onChange={inputImage}
                                />
                                <label
                                    className={(errors.image_sim || errors.imgSize_2) ? "custom-file-label invalid" : "custom-file-label"}
                                    htmlFor="inputGroupFile01"
                                >
                                    {state.pathSIM ? state.pathSIM : "Pilih Foto SIM"}
                                </label>
                            </div>
                        </div>
                        <ErrorMessage errors={errors} name="image_sim">
                            {({ message }) => <p className="error_info">{message}</p>}
                        </ErrorMessage>
                        <div className="btn-image">
                            <Image
                                src={state.image_sim}
                                style={{
                                    width: "13rem",
                                    height: "13rem",
                                    marginBottom: 15,
                                    alignSelf: "center"
                                }}
                            />
                            <p>
                                SIM
                            </p>
                        </div>
                    </div>
                </div>
                <div className="btn-container">
                    <Button variant="primary" type="submit" disabled={state.loading}>
                        {state.loading ? <Spinner animation="border" variant="secondary" /> : "Save"}
                    </Button>
                </div>
            </Form>
        </div>
    )
}

class FormSupirCom extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <FormSupirFunCom {...this.props} />
    }
}

export default FormSupirCom;
