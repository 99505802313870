import React, {Component} from "react"
import {Image, Row, Col} from "react-bootstrap"
import { NavLink } from "react-router-dom"
import '../styles/Colour_Style.css'
import '../styles/Form_Style.css'

class Timeout extends Component{
    render(){
        return(
            <div>
                <div className="unauthorize-container">
                    <Row>
                        <Col id="logo-unauthorized" sm={4}>
                            <Image src="assets/logo/mkm-transparent.png"/>
                        </Col>
                        <Col sm={7} style={{display:"flex", justifyContent:"center", alignItems:"center", marginLeft:"20px"}}>
                            <NavLink to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                                <h1 style={{fontSize:"5vh"}}>408<br/>Timeout</h1>
                            </NavLink>
                            <h2 style={{fontSize:"3vh"}}>Silahkan mencoba mengakses halaman ini beberapa saat lagi.</h2>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}
export default Timeout;