import React, { Component } from "react";
import SideBar from "../components/SideBar_Com";
import { Form, Button, Image, Spinner, Toast, ProgressBar, Container, Row, Col, Modal } from "react-bootstrap"
import '../styles/Colour_Style.css'
import '../styles/Form_Style.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../context/Auth_Context";
import { submitChangePass } from "../logics/SubmitForm_Logic";
import { useState } from "react";
import { useForm, ErrorMessage } from "react-hook-form";
import { getTime } from "../logics/CreateDateTime_Logic";
import { useEffect } from "react";

function GantiPasswordFunCom(props) {
    const { register, handleSubmit, errors, reset, clearError, setError, getValues } = useForm({
        mode: "onChange",
        validateCriteriaMode: "all"
    });

    const [state, setState] = useState({
        hiddenOld: false,
        hiddenNew: false,
        hiddenConfirm: false,
        loading: false
    })

    const [successSubmit,setSuccess] = useState(false)
    const { auth } = useAuth();


    const toggleShowOldPassword = () => {
        setState({
            ...state,
            hiddenOld: !state.hiddenOld
        });
    }
    const toggleShowNewPassword = () => {
        setState({
            ...state,
            hiddenNew: !state.hiddenNew
        });
    }
    const toggleShowConfirmPassword = () => {
        setState({
            ...state,
            hiddenConfirm: !state.hiddenConfirm
        });
    }

    const onSubmit = async (value) => {
        setState({
            ...state,
            loading: true
        })
        let success = false
        let message = []
        let status = []
        let response = await submitChangePass(auth.token, value)
        if (response[0] !== undefined && response[0].status === 200) {
            success = true;
        } else {
            if (response[1] !== undefined) {
                response[1].error.map((item) => {
                    status = status.concat(1);
                    message = message.concat("Status: " + response[1].status + " - " + response[1].message + ". " + item.msg);
                })
            }
            else {
                status = status.concat(1);
                message = message.concat("Status: " + response[0].status + " - " + response[0].message + ". " + response[0].error[0].msg);
            }
        }
        setState({
            ...state,
            loading: false
        })
        if(success) {
            setSuccess(true)
        } else {
            let idx = 0;
            status.map(function(item){
                props.generateToast(item,message[idx]);
                idx = idx + 1;
            })
        }
    }

    const matchPass = (value) => {
        clearError("missmatch");
        if (getValues("newpassword") === value) {
            clearError("confirm_pass");
            return true;
        }
        else {
            setError("missmatch", "notMatch", "Password konfirmasi tidak sesuai dengan password baru");
            return false;
        }
    }

    const close = () => {
        setSuccess(false)
        reset()
    }

    return (
        <div className="main-gantiPassword" >
            <div className="main-gantiPassword-child">
                <div className="logo-container gantiPassword">
                    <h1 style={{ fontSize: "1.5vw", textAlign: "center", padding: 10 }}>Anda Sedang Mengganti Password</h1>
                </div>
                <Form className="form-LoginForm gantiPassword" onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group as={Col} className="form-group-GantiPassword" controlId="formBasicEmail">
                        <Form.Label >Password Lama :</Form.Label>
                        <Form.Control
                            className={errors.oldpassword && "invalid"}
                            name="oldpassword"
                            type={!state.hiddenOld ? "password" : "text"}
                            ref={register({ required: "Input tidak boleh kosong" })}
                        />
                        <div className="eye-logo">
                            {!state.hiddenOld ?
                                <FontAwesomeIcon onClick={toggleShowOldPassword} icon={faEye} /> :
                                <FontAwesomeIcon onClick={toggleShowOldPassword} icon={faEye} />
                            }
                        </div>
                    </Form.Group>
                    <ErrorMessage errors={errors} name="oldpassword">
                        {({ message }) => <p className="error_info">{message}</p>}
                    </ErrorMessage>
                    <Form.Group as={Col} className="form-group-GantiPassword" controlId="formBasicPassword">
                        <Form.Label >Password Baru  :</Form.Label>
                        <Form.Control
                            className={errors.newpassword && "invalid"}
                            name="newpassword"
                            type={!state.hiddenNew ? "password" : "text"}
                            ref={register({
                                required: "Input tidak boleh kosong",
                                minLength: {
                                    value: 8,
                                    message: "Password minimal 8 digit"
                                },
                                validate: {
                                    same: value => value !== getValues("oldpassword") || "Password baru tidak boleh sama dengan password lama"
                                }
                            })}
                        />
                        <div className="eye-logo">
                            {!state.hiddenNew ?
                                <FontAwesomeIcon onClick={toggleShowNewPassword} icon={faEye} /> :
                                <FontAwesomeIcon onClick={toggleShowNewPassword} icon={faEye} />
                            }
                        </div>
                    </Form.Group>
                    <ErrorMessage errors={errors} name="newpassword">
                        {({ message }) => <p className="error_info">{message}</p>}
                    </ErrorMessage>
                    <Form.Group as={Col} className="form-group-GantiPassword" controlId="formBasicPassword">
                        <Form.Label >Konfirmasi Password  :</Form.Label>
                        <Form.Control
                            className={errors.missmatch && "invalid"}
                            name="confirmpassword"
                            type={!state.hiddenConfirm ? "password" : "text"}
                            ref={register({
                                validate: {
                                    notMatch: value => matchPass(value)
                                }
                            })}
                        />
                        <div className="eye-logo">
                            {!state.hiddenConfirm ?
                                <FontAwesomeIcon onClick={toggleShowConfirmPassword} icon={faEye} /> :
                                <FontAwesomeIcon onClick={toggleShowConfirmPassword} icon={faEye} />
                            }
                        </div>
                    </Form.Group>
                    <ErrorMessage errors={errors} name="missmatch">
                        {({ message }) => <p className="error_info">{message}</p>}
                    </ErrorMessage>
                    <div className="btn-container gantiPassword">
                        <Button variant="secondary" className="btn-secondary" onClick={()=>reset()}>
                            Reset
                                </Button>
                        <Button variant="primary" id="btn-gantiPassword" type="submit" disabled={state.loading}>
                            {state.loading ? <Spinner animation="border" variant="secondary" /> : "Submit"}
                        </Button>
                    </div>
                </Form>
                <Modal show={successSubmit} onHide={close}>
                    <Modal.Header closeButton>
                        <Modal.Title>Perhatian </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            Password anda telah berhasil diganti.
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={close}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
}

class GantiPassword extends Component {
    constructor(props) {
        super(props);
        this.state={
            showToast: [],
            toast_data: []
        }
    }

    close(index,pos){//destroy
        let show_arr = [...this.state.showToast];
        show_arr[index] = false;
        this.setState({
            showToast: show_arr
        },this.destroy(pos));
    }

    destroy(position){//nge rerender ketika masih ada yg di show apakah ga bakal masalah?
        let toast_data = [...this.state.toast_data];
        toast_data.splice(position,1,null);
        this.setState({
            toast_data : toast_data
        })
    }

    move = () => {
        this.props.history.push("/TableProduk");
    }
    generateToast = (status,message) => {
        let idx = this.state.showToast.length
        let header = "";
        let header_class = ""
        switch(status){
            case 0: //success
                header_class="success"
                header="Success!!"
                break;
            case 1: //error from input
                header_class="warning"
                header="Error!!"
                break;
            case 2: //error from server
                header_class="danger"
                header="Error!!"
                break;
            default:
                header_class=""
                header="Unknown"
                break;
        }
        let data = {
            class: header_class,
            header: header,
            msg: message,
            idx: idx,
            pos: this.state.toast_data.length
        }
        this.setState({
            toast_data: this.state.toast_data.concat(data),
            showToast: this.state.showToast.concat(true)
        })
    }

    render() {
        return (
            <div className="home">
                <SideBar />
                <div className="toast_container">
                {
                    this.state.toast_data.map((item) => {
                        return item !== null && (
                        <Toast animation className={item.class || "unknown"} show={this.state.showToast[item.idx]} onClose={() => this.close(item.idx,item.pos)} autohide delay={5000}>
                            <Toast.Header>
                                <strong className={"mr-auto "}>{item.header || "Unknown"}</strong>
                                <small>{getTime()}</small>
                            </Toast.Header>
                            <Toast.Body>{item.msg || "Some undocummented error occured. Please contact the administrator to look at this problem"}</Toast.Body>
                        </Toast>)
                    })
                }
                </div>
                <GantiPasswordFunCom generateToast={this.generateToast} {...this.props} />
            </div>
        )
    }
}
export default GantiPassword;