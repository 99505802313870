import React, { Component } from "react";
import SideBar from "../components/SideBar_Com";
import Table from "../components/Table_Com";
import '../styles/Table_Style.css'
import { fetchLog } from "../logics/FetchData_Logic";
import { AuthContext } from "../context/Auth_Context";
import Timeout from "./Timeout_Page";
import ModalLoading from "../components/ModalLoading_Com";

const $ = require('jquery')

class TableLog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            timeout: false,
            mainLoading: true
        }
    }

    async componentDidMount() {
        const { auth, setToken } = this.context;
        let obj = await fetchLog(auth.token);
        let data_table = null;
        let timeout = false;
        if (obj[0] !== undefined) {
            if (obj[0].status === 402) {
                let path = "/";
                this.props.history.push({
                    pathname: path,
                    state: "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi."
                })
            } else if (obj[0].status === 200) {
                data_table = {
                    data: obj[0].data,
                    searching: false,
                    scrollY: 341,
                    scrollCollapse: true,
                    columns: [
                        {
                            data: "tanggal",
                            render: function (data, type, row, meta) {
                                let date = new Date(data);
                                if (type === 'display' || type === 'filter') {
                                    if (data === "~") return data
                                    let year = date.getFullYear();
                                    let month = date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
                                    let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
                                    return day + "-" + month + "-" + year;
                                }
                                if (data === '~') return 0;
                                else return date.getTime() / 1000;
                            }
                        },
                        {
                            data: "tanggal",
                            render: function (data, type, row, meta) {
                                let date = new Date(data);
                                if (type === 'display' || type === 'filter') {
                                    if (data === "~") return data
                                    return (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":" + (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes());
                                }

                                if (data === '~') return 0;
                                else return date.getTime()/1000;
                            }
                        },
                        {
                            data: "idAdmin",
                            render: function (data) {
                                if (data === "~") return data
                                return data ? data.nama : "-"
                            }
                        },
                        { data: "aktivitas" },
                        {
                            data: "keterangan",
                            defaultContent: "tidak ada keterangan"
                        }
                    ],
                    order: [[0, 'desc'], [1, 'asc']],
                    columnDefs:[
                        {
                            targets: [0,1],
                            type: "dates"
                        },
                        {
                            targets: [2,3,4],
                            type: "text"
                        }
                    ]
                }
                if (obj[0].token) setToken(obj[0].token);
            } else { //error?

            }
        } else if (obj[1].status && obj[1].status === 408) {
            timeout = true
        } else {
            this.props.history.push("/500ServerError")
        }
        this.setState({
            data: data_table,
            timeout: timeout,
            mainLoading: false
        })
    }

    render() {
        return (
            <div className="home">
                {!this.state.timeout && <SideBar />}
                <div className="main">
                    {this.state.mainLoading && <ModalLoading />}
                    {this.state.timeout && <Timeout {...this.props} />}
                    <div className="container-table">
                        {this.state.data && <Table {...this.props} status={"TableLog"} data={this.state.data} />}
                    </div>
                </div>
            </div>
        );
    }
}

TableLog.contextType = AuthContext
export default TableLog;