export const getDateTime = () =>{
    let currDT = new Date();
    let year = currDT.getFullYear();
    let month = currDT.getMonth() < 9 ? "0"+(currDT.getMonth()+1) : (currDT.getMonth()+1);
    let date = currDT.getDate() < 10 ? "0"+currDT.getDate() : currDT.getDate();
    let hour = currDT.getHours() < 10 ? "0"+currDT.getHours() : currDT.getHours();
    let minute = currDT.getMinutes() < 10 ? "0"+currDT.getMinutes() : currDT.getMinutes();
    let seconds = currDT.getSeconds() < 10 ? "0"+currDT.getSeconds() : currDT.getSeconds();
    let offset = currDT.getTimezoneOffset() / 60 * -1;
    return year+"-"+month+"-"+date+"T"+hour+":"+minute+":"+seconds+(offset >= 0 ? 
        "+"+(offset < 10 ? "0"+offset : offset) : 
        "-"+(offset > -10 ? "0"+(offset*-1) : (offset*-1)))+":00";
}

export const getTime = () => {
    let currDT = new Date();
    let hour = currDT.getHours() < 10 ? "0"+currDT.getHours() : currDT.getHours();
    let minute = currDT.getMinutes() < 10 ? "0"+currDT.getMinutes() : currDT.getMinutes();
    return hour+":"+minute;
}

export const getLocalDate = () => {
    let currDT = new Date();
    let year = currDT.getFullYear();
    let month = currDT.getMonth() < 9 ? "0"+(currDT.getMonth()+1) : (currDT.getMonth()+1);
    let date = currDT.getDate() < 10 ? "0"+currDT.getDate() : currDT.getDate();
    return year+"-"+month+"-"+date;
}

export const changeFormat = (inp_date) => {
    if(!inp_date){
        return "-"
    }
    let currDT = new Date(inp_date);
    let year = currDT.getFullYear();
    let month = currDT.getMonth() < 9 ? "0"+(currDT.getMonth()+1) : (currDT.getMonth()+1);
    let date = currDT.getDate() < 10 ? "0"+currDT.getDate() : currDT.getDate();
    return date+"-"+month+"-"+year;
}