import { Modal, ProgressBar, Spinner } from "react-bootstrap";
import React, { Component, useState, useEffect } from "react";

class MyVerticallyCenteredModal extends React.Component {
  render() {
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-loading-content"
        keyboard="false"
        backdrop="false"
        animation="false"
        show={true}
      >
        <Modal.Body className="modal-loading-body">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Spinner className="spinner-loading" animation="border" />
            {this.props.usepbar && (
              <>
                <div style={{ width: "30vw", marginTop: "16px" }}>
                  {Math.floor(this.props.progression * 100)}%
                </div>
                <div style={{ marginBottom: "16px" }}>{this.props.msg}</div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

class ModalLoading extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <MyVerticallyCenteredModal {...this.props} />;
  }
}

export default ModalLoading;
