import React from "react";
import "./App.css";
//import "typeface-roboto";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./context/Auth_Context";
import Unauthorized from "./pages/Unauthorized_Page";

function PrivateRoute({ component: Component, access: Access, status:Status, ...rest }) {
  const { auth } = useAuth();
  const level = auth.level || localStorage.getItem("level") 
  return (
    <Route
      {...rest}
      render={props =>
        ((auth.token && auth.token === "") || (localStorage.getItem("tokens") === null)) ? 
          <Redirect to="/"/> : 
          ((level === Access || Access.includes(level)) ? 
            <Component status={Status} {...props}/> : <Unauthorized />)
      }
    />
  );
}

export default PrivateRoute;