import { Button, Form, Image, Spinner, Row, Col } from "react-bootstrap";
import React, { Component, useContext, useState, useEffect } from "react";
import Kota from "../data/Data";
import AutoComplete from "./AutoComplete_Com";
import { useForm, ErrorMessage } from "react-hook-form";
import "../styles/Colour_Style.css";
import "../styles/Form_Style.css";
import {
  handleTextInput,
  condensingWhitespace
} from "../logics/FormInput_Logic";
import {
  upload,
  submitPerusahaan,
  editPerusahaan
} from "../logics/SubmitForm_Logic";
import { deleteGambar } from "../logics/DeleteData_Logic";
import { useAuth } from "../context/Auth_Context";

function FormPerusahaanFunCom(props) {
  const { register, handleSubmit, errors, setValue, getValues } = useForm({
    mode: "onChange",
    defaultValues: {
      perusahaan: props.location.state ? props.location.state.nama : "",
      kota: props.location.state ? props.location.state.kota : "",
      id: props.location.state ? props.location.state._id : "",
      logo: props.location.state ? props.location.state.logo : "assets/image.png",
      state: props.location.state ? "edit" : "input"
    }
  });
  const { auth, setToken } = useAuth();
  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    image: props.location.state ? props.location.state.logo : "assets/image.png",
    formData: null,
    imgPath: null
  });

  const inputChange = event => {
    let val = handleTextInput(event.target.name, event);
    setValue(event.target.name, val);
  };

  const inputImage = event => {
    let obj = new FormData();
    obj.append("image", event.target.files[0]);
    setState({
      ...state,
      image: URL.createObjectURL(event.target.files[0]),
      formData: obj,
      imgPath: event.target.files[0].name
    });
  };
  const max = 2097152;
  const validateImage = value => {
    return value[0] ? value[0].size <= max : getValues("state") === "edit";
  };

  const onSubmit = async value => {
    setLoading(true);
    let token = auth.token;
    let success = false;
    let didUpload = false;
    let tahap_1 = true;
    let status = [];
    let message = [];
    value = condensingWhitespace(value);

    if (value.imgPhoto.length === 0) value.logo = getValues("logo");
    else {
      let response_image = await upload(auth.token, state.formData);
      if (response_image[0] && response_image[0].status === 200) {
        didUpload = true;
        value.logo = response_image[0].data[0].url;
        response_image[0].token && setToken(response_image[0].token);
      } else if (response_image[0] && response_image[0].status === 402) {
        let path = "/";
        props.history.push({
          pathname: path,
          state:
            "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi."
        });
      } else {
        if (response_image[1]) {
          response_image[1].error.map(item => {
            status = status.concat(1);
            message = message.concat(
              "Status: " +
                response_image[1].status +
                " - " +
                response_image[1].message +
                ". " +
                item.msg
            );
          });
        } else {
          status = status.concat(1);
          message = message.concat(
            "Status: " +
              response_image[0].status +
              " - " +
              response_image[0].message +
              ". " +
              response_image[0].error[0].msg
          );
        }
        tahap_1 = false;
      }
    }

    let response = null;

    if (tahap_1) {
      let response =
        getValues("state") === "edit"
          ? await editPerusahaan(value, token, getValues("id"))
          : await submitPerusahaan(value, token);
      if (response[0] && response[0].status === 200) {
        response[0].token && setToken(response[0].token);
        success = true;
      } else if (response[0] && response[0].status === 402) {
        let path = "/";
        this.props.history.push({
          pathname: path,
          state:
            "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi."
        });
      } else {
        if (response[1]) {
          response[1].error.map(item => {
            status = status.concat(1);
            message = message.concat(
              "Status: " +
                response[1].status +
                " - " +
                response[1].message +
                ". " +
                item.msg
            );
          });
        } else {
          status = status.concat(1);
          message = message.concat(
            "Status: " +
              response[0].status +
              " - " +
              response[0].message +
              ". " +
              response[0].error[0].msg
          );
        }
        let responseDel = didUpload
          ? await deleteGambar(auth.token, value.logo)
          : null;
        if (responseDel !== null) {
          if (responseDel[0] && responseDel[0].status === 402) {
            let path = "/";
            props.history.push({
              pathname: path,
              state:
                "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi."
            });
          } else if (responseDel[0] && responseDel[0].status === 200) {
            responseDel[0].token && setToken(responseDel[0].token);
          } else {
            if (responseDel[1]) {
              responseDel[1].error.map(item => {
                status = status.concat(1);
                message = message.concat(
                  "Status: " +
                    responseDel[1].status +
                    " - " +
                    responseDel[1].message +
                    ". " +
                    item.msg
                );
              });
            } else {
              status = status.concat(1);
              message = message.concat(
                "Status: " +
                  responseDel[0].status +
                  " - " +
                  responseDel[0].message +
                  ". " +
                  responseDel[0].error[0].msg
              );
            }
          }
        }
      }
    }

    setLoading(false);
    if (success) {
      props.showModal(getValues("state"), value.perusahaan);
    } else {
      let idx = 0;
      status.map(function(item) {
        props.generateToast(item, message[idx]);
        idx = idx + 1;
      });
    }
  };

  const childSetValue = (name, value) => {
    setValue(name, value);
  };

  return (
    <div className="container">
      {getValues("state") === "edit" && (
        <h4>Sedang mengedit perusahaan cabang: {props.location.state.nama}</h4>
      )}
      <Form className="form" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Form.Group as={Col}>
            <Form.Label>Nama Perusahaan :</Form.Label>
            <Form.Control
              className={errors.perusahaan && "invalid"}
              name="perusahaan"
              type="text"
              //value={getValues("perusahaan")}
              onChange={inputChange}
              ref={register({ required: "Input tidak boleh kosong" })}
            />
            <ErrorMessage errors={errors} name="perusahaan">
              {({ message }) => <p className="error_info">{message}</p>}
            </ErrorMessage>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <Form.Label>Kota :</Form.Label>
            {/* <Typeahead
                                id="basic-typeahead-single"
                                labelKey="name"
                                onChange={setSingleSelections}
                                options={Kota}
                                selected={singleSelections}
                                reference={register({ required: "Input tidak boleh kosong" })}
                                setValue={childSetValue}
                                errors={errors}
                        /> */}
            <AutoComplete
              suggestions={Kota}
              reference={register({ required: "Input tidak boleh kosong" })}
              setValue={childSetValue}
              errors={errors}
            />
            <ErrorMessage errors={errors} name="kota">
              {({ message }) => <p className="error_info">{message}</p>}
            </ErrorMessage>
          </Form.Group>
        </Row>
        <div className="container-image">
          <div className="form-image">
            <div className="input-group">
              <div className="custom-file">
                <input
                  accept="image/*"
                  type="file"
                  className="custom-file-input"
                  name="imgPhoto"
                  onChange={inputImage}
                  ref={
                    getValues("state") !== "edit"
                      ? register({
                          required: true,
                          validate: value =>
                            validateImage(value) ||
                            "Gambar yang anda masukkan melebihi batas maksimal (2MB)"
                        })
                      : register({
                          validate: value =>
                            validateImage(value) ||
                            "Gambar yang anda masukkan melebihi batas maksimal (2MB)"
                        })
                  }
                />
                <label
                  className={
                    errors.imgPhoto
                      ? "custom-file-label invalid"
                      : "custom-file-label"
                  }
                  htmlFor="inputGroupFile01"
                >
                  {state.imgPath ? state.imgPath : "Logo Perusahaan"}
                </label>
              </div>
            </div>
            <div className="btn-image">
              <Image
                src={state.image}
                style={{
                  width: "13rem",
                  height: "13rem",
                  marginBottom: 15,
                  alignSelf: "center"
                }}
              />
            </div>
          </div>
        </div>
        <ErrorMessage errors={errors} name="imgPhoto">
          {({ message }) => <p className="error_info">{message}</p>}
        </ErrorMessage>
        <div className="btn-container">
          <Button variant="primary" type="submit" disabled={loading}>
            {loading ? (
              <Spinner animation="border" variant="secondary" />
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </Form>
    </div>
  );
}

class FormPerusahaanCom extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <FormPerusahaanFunCom {...this.props} />;
  }
}

export default FormPerusahaanCom;
