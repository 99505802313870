import { Button, Form, Image, Col, Row, Spinner } from "react-bootstrap";
import React, { Component, useState } from "react";
import { useForm, ErrorMessage } from "react-hook-form";
import Kota from "../data/Data"
import AutoComplete from "./AutoComplete_Com"
import '../styles/Colour_Style.css'
import '../styles/Form_Style.css'
import { upload, submitAdmin, editAdmin } from "../logics/SubmitForm_Logic"
import { handleTextInput, handleNumberInput, condensingWhitespace } from "../logics/FormInput_Logic";
import { useAuth } from "../context/Auth_Context"
import { deleteGambar } from "../logics/DeleteData_Logic";


function FormAdminFunCom(props) {
    const { register, handleSubmit, errors, setError, clearError, setValue, getValues } = useForm({
        validateCriteriaMode: "all",
        mode: "onChange",
        defaultValues: {
            name: props.data[1] ? props.data[1].nama : "",
            no_hp: props.data[1] ? props.data[1].no_hp : "",
            email: props.data[1] ? props.data[1].email : "",
            nik: props.data[1] ? props.data[1].nik : "",
            perusahaan_cabang: props.data[1] ? props.data[1].idPerusahaan._id : "",
            //perusahaan_cabang_id: props.data[1] ? props.data[1].idPerusahaan._id : "",
            kota: props.data[1] ? props.data[1].kota : "",
            username: props.data[1] ? props.data[1].username : "",
            password: "",
            //image: props.data[1] ? props.data[1].path_uri_ktp : "assets/image.png",
            id: props.data[1] ? props.data[1]._id : "",
            state: props.data[1] ? "edit" : "input"
        }
    });
    const [state, setState] = useState({
        image: props.data[1] ? props.data[1].path_uri_ktp : "assets/image.png",
        formData: null,
        loading: false,
        imgPath: null
    })


    const { auth, setToken } = useAuth();
    const max = 2097152

    const validateImage = (value) => {
        return value[0] ? value[0].size <= max : getValues("state") === "edit";
    }

    const onSubmit = async (value) => {
        setState({
            ...state,
            loading: true
        })

        let success = false;
        let didUpload = false;
        let tahap_1 = true;
        let status = [];
        let message = [];
        value.admin_level = 2
        value = condensingWhitespace(value)
        if (value.imgPhoto.length === 0) value.path_uri_ktp = state.image;
        else {
            let obj = await upload(auth.token, state.formData);
            if (obj[0] && obj[0].status === 200) {
                didUpload = true;
                value.path_uri_ktp = obj[0].data[0].url
                obj[0].token && setToken(obj[0].token)
            }
            else if (obj[0] && obj[0].status === 402) {
                let path = "/";
                props.history.push({
                    pathname: path,
                    state: "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi."
                })
            } else {
                if (obj[1]) {
                    obj[1].error.map((item) => {
                        status = status.concat(1);
                        message = message.concat("Status: " + obj[1].status + " - " + obj[1].message + ". " + item.msg);
                    })
                } else {
                    status = status.concat(1);
                    message = message.concat("Status: " + obj[0].status + " - " + obj[0].message + ". " + obj[0].error[0].msg);
                }
                tahap_1 = false;
            }
        }

        let response = null;
        if (tahap_1) {
            response = getValues("state") === "edit" ? await editAdmin(value, auth.token, getValues("id")) : await submitAdmin(value, auth.token);
            if (response[0] && response[0].status === 200) {
                response[0].token && setToken(response[0].token)
                success = true;
            } else if (response[0] && response[0].status === 402) {
                let path = "/";
                this.props.history.push({
                    pathname: path,
                    state: "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi."
                })
            } else {
                if (response[1]) {
                    response[1].error.map((item) => {
                        status = status.concat(1);
                        message = message.concat("Status: " + response[1].status + " - " + response[1].message + ". " + item.msg);
                    })
                }
                else {
                    status = status.concat(1);
                    message = message.concat("Status: " + response[0].status + " - " + response[0].message + ". " + response[0].error[0].msg);
                }
                let responseDel = didUpload ? await deleteGambar(auth.token, value.path_uri_ktp) : null;
                if (responseDel !== null) {
                    if (responseDel[0] && responseDel[0].status === 402) {
                        let path = "/";
                        props.history.push({
                            pathname: path,
                            state: "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi."
                        })
                    } else if (responseDel[0] && responseDel[0].status === 200) {
                        responseDel[0].token && setToken(responseDel[0].token)
                    } else {
                        if (responseDel[1]) {
                            responseDel[1].error.map((item) => {
                                status = status.concat(1);
                                message = message.concat("Status: " + responseDel[1].status + " - " + responseDel[1].message + ". " + item.msg);
                            })
                        } else {
                            status = status.concat(1);
                            message = message.concat("Status: " + responseDel[0].status + " - " + responseDel[0].message + ". " + responseDel[0].error[0].msg);
                        }
                    }
                }
            }
        }
        setState({
            ...state,
            loading: false
        })
        if (success) {
            getValues("state") === "input" ?
                props.showModal(getValues("state"), response[0].data[0].nama, response[0].data[1].password) :
                props.showModal(getValues("state"), value.name, response[0].message);
        } else {
            let idx = 0;
            status.map(function (item) {
                props.generateToast(item, message[idx]);
                idx = idx + 1;
            })
        }
    }

    const childSetValue = (name, value) => {
        setValue(name, value);
    }

    const inputImage = (event) => {
        let objFormData = new FormData();
        objFormData.append("image", event.target.files[0]);
        setState({
            ...state,
            image: URL.createObjectURL(event.target.files[0]),
            formData: objFormData,
            imgPath: event.target.files[0].name
        })
    }

    const matchPass = (value) => {
        clearError("missmatch");
        if (getValues("password") === getValues("confirm_pass")) {
            clearError("confirm_pass");
            return true;
        }
        else {
            setError("missmatch", "notMatch", "Password konfirmasi tidak sesuai dengan password masukan");
            return false;
        }
    }

    const validatePass = (value) => {
        clearError("req");
        if (value === "") {
            clearError("req");
            return true;
        } else if (value.length < 8) {
            setError("req", "minLength", "Password tidak boleh kurang dari 8 karakter atau harus kosong")
            return false;
        } else return true;
    }

    const handleChange = (event) => {
        let val = "";
        if (event.target.name === "no_hp" || event.target.name === "nik") val = handleNumberInput(event);
        else val = handleTextInput(event.target.name, event);
        setValue(event.target.name, val);
    }

    return (
        <div className="container">
            {getValues("state") === "edit" && <h4>Sedang mengedit informasi dari admin: {props.data[1].nama}</h4>}
            <Form className="form" onSubmit={handleSubmit(onSubmit)}>
                <Form.Group controlId="formBasicName">
                    <Form.Label >Nama :</Form.Label>
                    <Form.Control
                        className={errors.name && "invalid"}
                        name="name"
                        type="text"
                        ref={register({ required: "Input tidak boleh kosong" })}
                        onChange={handleChange} />
                    <ErrorMessage errors={errors} name="name">
                        {({ message }) => <p className="error_info">{message}</p>}
                    </ErrorMessage>
                </Form.Group>
                <Form.Row>
                    <Form.Group as={Col} controlId="formBasicHP">
                        <Form.Label for="phone">No HP  :</Form.Label>
                        <Form.Control
                            className={errors.no_hp && "invalid"}
                            type="tel"
                            name="no_hp"
                            ref={register({
                                required: "Input tidak boleh kosong",
                                pattern: { value: /^[+-]?\d+$/, message: "Format salah, contoh yang benar: +6221222333 / 021222333" },
                                maxLength: { value: 16, message: "Nomor terlalu panjang, maksimal 16 digit" },
                                minLength: { value: 8, message: "Nomor terlalu pendek, minimal 8 digit" }
                            })}
                            onChange={handleChange} />
                        <ErrorMessage errors={errors} name="no_hp">
                            {({ message }) => <p className="error_info">{message}</p>}
                        </ErrorMessage>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formBasicEmail">
                        <Form.Label >Email :</Form.Label>
                        <Form.Control
                            className={errors.email && "invalid"}
                            type="email"
                            name="email"
                            ref={register({
                                required: "Input tidak boleh kosong",
                                pattern: { value: /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/, message: "Tipe e-mail yang dimasukan belum sesuai. Contoh yang benar: JohnDoe@gmail.com" }
                            })}
                            onChange={handleChange} />
                        <ErrorMessage errors={errors} name="email">
                            {({ message }) => <p className="error_info">{message}</p>}
                        </ErrorMessage>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formBasicNIK">
                        <Form.Label >No NIK  :</Form.Label>
                        <Form.Control
                            className={errors.nik && "invalid"}
                            name="nik"
                            type="text"
                            maxLength="16"
                            ref={register({
                                required: "Input tidak boleh kosong",
                                minLength: {
                                    value: 16,
                                    message: "Input tidak boleh kurang dari 16 digit"
                                }
                            })} //ada spesifik brp min - max?
                            onChange={handleChange} />
                        <ErrorMessage errors={errors} name="nik">
                            {({ message }) => <p className="error_info">{message}</p>}
                        </ErrorMessage>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formBasicKota">
                        <Form.Label >Kota  :</Form.Label>
                        <AutoComplete
                            suggestions={Kota}
                            reference={register({ required: "Input tidak boleh kosong" })}
                            setValue={childSetValue}
                            errors={errors}
                        />
                        {/* <Typeahead
                            id="basic-typeahead-single"
                            labelKey="name"
                            onChange={setSingleSelections}
                            options={Kota}
                            selected={singleSelections}
                            reference={register({ required: "Input tidak boleh kosong" })}
                            setValue={childSetValue}
                            errors={errors}
                         /> */}
                        <ErrorMessage errors={errors} name="kota">
                            {({ message }) => <p className="error_info">{message}</p>}
                        </ErrorMessage>
                    </Form.Group>
                </Form.Row>
                <Form.Group controlId="formBasicPerusahaan">
                    <Form.Label >Nama Perusahaan :</Form.Label>
                    <Form.Control
                        className={errors.perusahaan_cabang && "invalid"}
                        name="perusahaan_cabang"
                        as="select"
                        ref={register({ required: "Input tidak boleh kosong" })}
                    >
                        <option value="">Pilih Perusahaan</option>
                        {
                            props.data[0].map(
                                data => <option value={data._id} key={data._id}>{data.nama}</option>
                            )
                        }
                    </Form.Control>
                    <ErrorMessage errors={errors} name="perusahaan_cabang">
                        {({ message }) => <p className="error_info">{message}</p>}
                    </ErrorMessage>
                </Form.Group>
                <Form.Group controlId="formBasicUsername">
                    <Form.Label >Username :</Form.Label>
                    <Form.Control
                        className={errors.username && "invalid"}
                        type="text"
                        name="username"
                        ref={register({ required: "Input tidak boleh kosong" })} //berapa min - max
                        onChange={handleChange} />
                    <ErrorMessage errors={errors} name="username">
                        {({ message }) => <p className="error_info">{message}</p>}
                    </ErrorMessage>
                </Form.Group>
                {getValues("state") === "edit" &&
                    <Form.Row>
                        <Form.Group as={Col} controlId="formBasicPassword">
                            <Form.Label >New Password :<em> (Opsional)</em></Form.Label>
                            <Form.Control
                                className={errors.password && "invalid"}
                                type="Password"
                                name="password"
                                ref={register({
                                    validate: value => validatePass(value)
                                })}
                            />
                            <ErrorMessage errors={errors} name="req">
                                {({ message }) => <p className="error_info">{message}</p>}
                            </ErrorMessage>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formBasicConfirmPassword">
                            <Form.Label >Confirm Password :<em> (Opsional)</em></Form.Label>
                            <Form.Control
                                className={errors.confirm_pass && "invalid"}
                                type="Password"
                                name="confirm_pass"
                                ref={register({
                                    validate: {
                                        notMatch: value => matchPass(value)
                                    }
                                })}
                            />
                            <ErrorMessage errors={errors} name="missmatch">
                                {({ message }) => <p className="error_info">{message}</p>}
                            </ErrorMessage>
                        </Form.Group>
                    </Form.Row>}
                <div className="container-image">
                    <div className="form-image">
                        {getValues("state") === "edit" &&
                            <em> (Opsional)</em>
                        }
                        <div className="input-group">
                            <div className="custom-file">
                                <label
                                    className={errors.imgPhoto ? "custom-file-label invalid" : "custom-file-label"}
                                    htmlFor="inputGroupFile01"
                                >
                                    {state.imgPath ? state.imgPath : "Pilih Foto KTP" } 
                                </label>
                                <input
                                    accept="image/*"
                                    type="file"
                                    className="custom-file-input"
                                    name="imgPhoto"
                                    onChange={inputImage}
                                    ref={getValues("state") !== "edit" ? register({
                                        required: true,
                                        validate: value => validateImage(value) || 'Gambar yang anda masukkan melebihi batas maksimal (2MB)'
                                    }) : register({
                                        validate: value => validateImage(value) || 'Gambar yang anda masukkan melebihi batas maksimal (2MB)'
                                    })}
                                />
                            </div>
                        </div>
                        <div className="btn-image">
                            <Image
                                src={state.image}
                                style={{
                                    width: "10rem",
                                    height: "10rem",
                                    marginBottom: 15,
                                    alignSelf: "center"
                                }}
                            />
                            <p>
                                KTP
                            </p>
                        </div>

                    </div>

                </div>
                <ErrorMessage errors={errors} name="imgPhoto">
                    {({ message }) => <p className="error_info">{message}</p>}
                </ErrorMessage>
                <div className="btn-container">
                    <Button variant="primary" type="submit" disabled={state.loading}>
                        {state.loading ? <Spinner animation="border" variant="secondary" /> : "Save"}
                    </Button>
                </div>
            </Form>
        </div>
    )
}


class FormAdminCom extends Component {
    constructor(props) {
        super(props);
    }

    render() { return <FormAdminFunCom {...this.props} /> }
}

export default FormAdminCom;
