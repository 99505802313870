import {Form} from "react-bootstrap";
import React, { Component } from "react";
import '../styles/Colour_Style.css'
import '../styles/Form_Style.css'
class FormPengirimanNotaCom extends Component{
    render(){
        return(
                <div className="container">
                    <Form className="form">
                        <Form.Group>
                            <div>
                                <div className="btn-text">
                                    <Form.Label >Tanggal Pengiriman :</Form.Label>
                                </div>
                                <div className="btn-input">
                                    <Form.Control plaintext readOnly defaultValue="12-12-2020" />
                                </div>
                            </div>                                
                        </Form.Group>                
                        <Form.Group>
                            <div>
                                <div className="btn-text">
                                    <Form.Label >No PO  :</Form.Label>
                                </div>
                                <div className="btn-input">
                                    <Form.Control  plaintext readOnly defaultValue="12-12-2020"/>
                                </div>
                            </div>                   
                        </Form.Group>
                        <Form.Group>
                            <div>
                                <div className="btn-text">
                                    <Form.Label >No Nota Pengiriman :</Form.Label>
                                </div>
                                <div className="btn-input">
                                    <Form.Control plaintext readOnly defaultValue="12-12-2020" />
                                </div>
                            </div>                                
                        </Form.Group>                
                        <Form.Group>
                            <div>
                                <div className="btn-text">
                                    <Form.Label >Asal Pengiriman  :</Form.Label>
                                </div>
                                <div className="btn-input">
                                    <Form.Control  plaintext readOnly defaultValue="12-12-2020"/>
                                </div>
                            </div>                   
                        </Form.Group>
                        <Form.Group>
                            <div>
                                <div className="btn-text">
                                    <Form.Label >Titik Pengambilan Barang :</Form.Label>
                                </div>
                                <div className="btn-input">
                                    <Form.Control plaintext readOnly defaultValue="12-12-2020" />
                                </div>
                            </div>                                
                        </Form.Group>                
                        <Form.Group>
                            <div>
                                <div className="btn-text">
                                    <Form.Label >Tujuan Pengiriman :</Form.Label>
                                </div>
                                <div className="btn-input">
                                    <Form.Control  plaintext readOnly defaultValue="12-12-2020"/>
                                </div>
                            </div>                   
                        </Form.Group>
                        <Form.Group>
                            <div>
                                <div className="btn-text">
                                    <Form.Label >Nama Supir :</Form.Label>
                                </div>
                                <div className="btn-input">
                                    <Form.Control plaintext readOnly defaultValue="12-12-2020" />
                                </div>
                            </div>                                
                        </Form.Group>                
                        <Form.Group>
                            <div>
                                <div className="btn-text">
                                    <Form.Label >Jenis Kendaraan :</Form.Label>
                                </div>
                                <div className="btn-input">
                                    <Form.Control  plaintext readOnly defaultValue="12-12-2020"/>
                                </div>
                            </div>                   
                        </Form.Group>
                        <Form.Group>
                            <div>
                                <div className="btn-text">
                                    <Form.Label >No Polisi Kendaraan :</Form.Label>
                                </div>
                                <div className="btn-input">
                                    <Form.Control plaintext readOnly defaultValue="12-12-2020" />
                                </div>
                            </div>                                
                        </Form.Group>                
                        <Form.Group>
                            <div>
                                <div className="btn-text">
                                    <Form.Label >Biaya Premi :</Form.Label>
                                </div>
                                <div className="btn-input">
                                    <Form.Control plaintext readOnly defaultValue="12-12-2020" />
                                </div>
                            </div>                                
                        </Form.Group>                
                        <Form.Group>
                            <div>
                                <div className="btn-text">
                                    <Form.Label >Nama Perusahaan Pemilik Kendaraan :</Form.Label>
                                </div>
                                <div className="btn-input">
                                    <Form.Control  plaintext readOnly defaultValue="12-12-2020"/>
                                </div>
                            </div>                   
                        </Form.Group>
                        <Form.Group>
                            <div>
                                <div className="btn-text">
                                    <Form.Label >Jenis Produk Yang Dibawa :</Form.Label>
                                </div>
                                <div className="btn-input">
                                    <Form.Control plaintext readOnly defaultValue="12-12-2020" />
                                </div>
                            </div>                                
                        </Form.Group>                
                        <Form.Group>
                            <div>
                                <div className="btn-text">
                                    <Form.Label >Quantity Produk Yang Dibawa  :</Form.Label>
                                </div>
                                <div className="btn-input">
                                    <Form.Control  plaintext readOnly defaultValue="12-12-2020"/>
                                </div>
                            </div>                   
                        </Form.Group>
                        <Form.Group>
                            <div>
                                <div className="btn-text">
                                    <Form.Label >Harga Produk :</Form.Label>
                                </div>
                                <div className="btn-input">
                                    <Form.Control plaintext readOnly defaultValue="RP. 500.000,-" />
                                </div>
                            </div>                                
                        </Form.Group>                
                        <Form.Group>
                            <div>
                                <div className="btn-text">
                                    <Form.Label >No Segel Atas :</Form.Label>
                                </div>
                                <div className="btn-input">
                                    <Form.Control plaintext readOnly defaultValue="12-12-2020" />
                                </div>
                            </div>                                
                        </Form.Group>                
                        <Form.Group>
                            <div>
                                <div className="btn-text">
                                    <Form.Label >No Segel Bawah  :</Form.Label>
                                </div>
                                <div className="btn-input">
                                    <Form.Control  plaintext readOnly defaultValue="12-12-2020"/>
                                </div>
                            </div>                   
                        </Form.Group>
                        <Form.Group>
                            <div>
                                <div className="btn-text">
                                    <Form.Label >Biaya Portal / Penyeberangan :</Form.Label>
                                </div>
                                <div className="btn-input">
                                    <Form.Control plaintext readOnly defaultValue="12-12-2020" />
                                </div>
                            </div>                                
                        </Form.Group>                
                        <Form.Group>
                            <div>
                                <div className="btn-text">
                                    <Form.Label >Catatan Tambahan  :</Form.Label>
                                </div>
                                <div className="btn-input">
                                    <Form.Control  plaintext readOnly defaultValue="12-12-2020"/>
                                </div>
                            </div>                   
                        </Form.Group>
                        <Form.Group>
                            <div>
                                <div className="btn-text">
                                    <Form.Label >Nama Penanggung Jawab Pengirim :</Form.Label>
                                </div>
                                <div className="btn-input">
                                    <Form.Control plaintext readOnly defaultValue="12-12-2020" />
                                </div>
                            </div>                                
                        </Form.Group>                
                        <Form.Group>
                            <div>
                                <div className="btn-text">
                                    <Form.Label >Nama Supir  :</Form.Label>
                                </div>
                                <div className="btn-input">
                                    <Form.Control  plaintext readOnly defaultValue="12-12-2020"/>
                                </div>
                            </div>                   
                        </Form.Group>
                        <Form.Group>
                            <div>
                                <div className="btn-text">
                                    <Form.Label >Nama Penerima :</Form.Label>
                                </div>
                                <div className="btn-input">
                                    <Form.Control plaintext readOnly defaultValue="12-12-2020" />
                                </div>
                            </div>                                
                        </Form.Group>                
                        <Form.Group>
                            <div>
                                <div className="btn-text">
                                    <Form.Label >Keterangan  :</Form.Label>
                                </div>
                                <div className="btn-input">
                                    <Form.Control  plaintext readOnly defaultValue="12-12-2020"/>
                                </div>
                            </div>                   
                        </Form.Group>
                    </Form>
                </div>
        )
    }
}

export default FormPengirimanNotaCom;
