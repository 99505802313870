import React, { Component } from "react";
import { Modal, Toast, Button } from "react-bootstrap";
import FormNotaCom from "../components/FormNota_Com";
import SideBar from "../components/SideBar_Com";
import { getTime } from "../logics/CreateDateTime_Logic";
import { AuthContext } from "../context/Auth_Context";
import {
  fetchNomorNota,
  fetchNotaData,
  fetchSpecificPengiriman,
} from "../logics/FetchData_Logic";
import Timeout from "./Timeout_Page";
import ModalLoading from "../components/ModalLoading_Com";

class FormNotaEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showToast: [],
      toast_data: [],
      showModal: false,
      //fetchNomor: null,
      notaData: null,
      fetchProgress: 0,
      fetchMessage: "Sedang mengambil nomor nota terbaru",
      modalData: {},
      timeout: false,
      mainLoading: true,
      isChanged: false,
      resetForm: null,
      sideBarProps: null,
    };

    this.totalProgress = 8;
  }

  close(index, pos) {
    //destroy
    let show_arr = [...this.state.showToast];
    show_arr[index] = false;
    this.setState(
      {
        showToast: show_arr,
      },
      this.destroy(pos)
    );
  }

  destroy(position) {
    //nge rerender ketika masih ada yg di show apakah ga bakal masalah?
    let toast_data = [...this.state.toast_data];
    toast_data.splice(position, 1, null);
    this.setState({
      toast_data: toast_data,
    });
  }

  move = () => {
    this.props.history.push("/TablePengirimanPerusahaan");
  };

  showModal = (data, edit) => {
    this.setState({
      showModal: true,
      modalData: {
        changed: data.changed,
        no_nota: data.to,
        edit: edit,
      },
    });
  };

  generateToast = (status, message) => {
    let idx = this.state.showToast.length;
    let header = "";
    let header_class = "";
    switch (status) {
      case 0: //success
        header_class = "success";
        header = "Success!!";
        break;
      case 1: //error from input
        header_class = "warning";
        header = "Error!!";
        break;
      case 2: //error from server
        header_class = "danger";
        header = "Error!!";
        break;
      default:
        header_class = "";
        header = "Unknown";
        break;
    }
    let data = {
      class: header_class,
      header: header,
      msg: message,
      idx: idx,
      pos: this.state.toast_data.length,
    };
    this.setState({
      toast_data: this.state.toast_data.concat(data),
      showToast: this.state.showToast.concat(true),
    });
  };

  // async componentDidMount(){
  //     const {auth, setToken } = this.context
  //     let response = await fetchNomorNota(auth.token);
  //     let timeout = false;
  //     let fetchNomor = null;
  //     if(response[0] !== undefined){
  //         if(response[0].status===200){
  //             fetchNomor = response[0].data[0].no_nota;
  //             if (response[0].token) setToken(response[0].token);
  //         } else if(response[0].status === 402) {
  //             let path = "/";
  //             this.props.history.push({
  //                 pathname: path,
  //                 state: "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi."
  //             })
  //         } else { //error?

  //         }
  //     } else if (response[1].status && response[1].status === 408) {
  //         timeout = true
  //     } else {
  //         this.props.history.push("/500ServerError")
  //     }
  //     this.setState({
  //         fetchNomor: fetchNomor,
  //         timeout: timeout,
  //         mainLoading: false
  //     })
  // }

  updateProgressFetch = (msg) =>
    this.setState({
      fetchProgress: this.state.fetchProgress + 1,
      fetchMessage: msg,
    });
  error = () => this.props.history.push("/500ServerError");
  timeOut = () => this.setState({ timeout: true, mainLoading: false });
  relogin = () =>
    this.props.history.push({
      pathname: "/",
      state:
        "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi.",
    });

  async componentDidMount() {
    if (!this.props.location.state) {
      this.props.history.replace("/400BadRequest");
      return;
    }

    const { auth, setToken } = this.context;
    let response = await fetchNotaData(
      auth.token,
      this.updateProgressFetch,
      this.error,
      this.relogin,
      this.timeOut
    );
    let sideBarProps = response;
    if (response) {
      setToken(response.token);
      if (this.props.location.state) {
        this.setState({
          fetchMessage: "Sedang mengambil detil data nota pengiriman",
        });
        let response_2 = await fetchSpecificPengiriman(
          auth.token,
          this.props.location.state._id
        );
        if (response_2[0] && response_2[0].status === 200) {
          let specificNota = response_2[0].data[0];
          let id_transportir = specificNota.asal_pengiriman._id;
          let id_portal = specificNota.titik_pengambilan._id;
          let id_tujuan = specificNota.tujuan_pengiriman._id;
          let id_supir = specificNota.supirId._id;
          let id_produk = specificNota.jenis_produk._id;
          let id_kendaraan = specificNota.kendaraanId._id;
          let arr_check = {
            kendaraan: false, //not registered
            produk: false,
            supir: false,
            tujuan: false,
            portal: false,
            transportir: false,
          }
          //check if kendaraan still exist
          for(let i=0; i < response.kendaraan.kendaraan.length; i++){
            if(response.kendaraan.kendaraan[i]._id === id_kendaraan){
              arr_check['kendaraan'] = true;
              break;
            }
          }
          if(!arr_check["kendaraan"] && response.kendaraan.otherId === id_kendaraan) arr_check["kendaraan"] = true;

          //check if customers still exist
          for(let i=0; i < response.perusahaan_penerima.customers.length; i++){
            if(response.perusahaan_penerima.customers[i]._id === id_tujuan){
              arr_check['tujuan'] = true;
              break;
            }
          }
          if(!arr_check["tujuan"] && response.perusahaan_penerima.otherId === id_tujuan) arr_check["tujuan"] = true;

          //check if transportir still exist
          for(let i=0; i < response.perusahaan_pengirim.length; i++){
            if(response.perusahaan_pengirim[i]._id === id_transportir){
              arr_check['transportir'] = true;
              break;
            }
          }

          //check if portal still exist
          for(let i=0; i < response.portal.length; i++){
            if(response.portal[i]._id === id_portal){
              arr_check['portal'] = true;
              break;
            }
          }

          //check if produk still exist
          for(let i=0; i < response.produk.length; i++){
            if(response.produk[i]._id === id_produk){
              arr_check['produk'] = true;
              break;
            }
          }

          //check if supir still exist
          for(let i=0; i < response.supir.supir.length; i++){
            if(response.supir.supir[i]._id === id_supir){
              arr_check['supir'] = true;
              break;
            }
          }
          if(!arr_check["supir"] && response.supir.otherId === id_supir) arr_check["supir"] = true;

          for(const key in arr_check){
            if(!arr_check[key]){
              if(key === "kendaraan") response_2[0].data[0].kendaraanId._id = ""
              else if(key === "produk") response_2[0].data[0].jenis_produk._id = ""
              else if(key === "supir") response_2[0].data[0].supirId._id = ""
              else if(key === "tujuan") response_2[0].data[0].tujuan_pengiriman._id = ""
              else if(key === "portal") response_2[0].data[0].titik_pengambilan._id = ""
              else if(key === "transportir") response_2[0].data[0].asal_pengiriman._id = ""
            }
          }

          response = {
            ...response,
            detailNota: response_2[0].data[0],
          };
          this.updateProgressFetch("Selesai mengambil data");
        } else if (response[0] && response[0].status === 402) {
          this.relogin();
          return;
        } else if (response[1] && response[1].status === 408) {
          this.timeOut();
          return;
        } else {
          //throw ke server error
          this.error();
          return;
        }
      }
      this.setState({
        mainLoading: false,
        notaData: response,
        sideBarProps: sideBarProps,
      });
    } else return;
  }

  render() {
    return (
      <div className="home">
        {!this.state.timeout && <SideBar notaData={this.state.sideBarProps}/>}
        <div className="toast_container">
          {this.state.toast_data.map((item) => {
            return (
              item !== null && (
                <Toast
                  animation
                  className={item.class || "unknown"}
                  show={this.state.showToast[item.idx]}
                  onClose={() => this.close(item.idx, item.pos)}
                  autohide
                  delay={5000}
                >
                  <Toast.Header>
                    <strong className={"mr-auto "}>
                      {item.header || "Unknown"}
                    </strong>
                    <small>{getTime()}</small>
                  </Toast.Header>
                  <Toast.Body>
                    {item.msg ||
                      "Some undocummented error occured. Please contact the administrator to look at this problem"}
                  </Toast.Body>
                </Toast>
              )
            );
          })}
          <Modal
            show={this.state.showModal}
            onHide={this.move}
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>Sukses!!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.modalData.edit ? (
                <p>
                  Detail Nota No. <b>{this.state.modalData.no_nota}</b> telah
                  berhasil dirubah
                </p>
              ) : (
                <p>
                  Nota telah berhasil dibuat, dengan nomor nota yang terdaftar
                  diserver: <b>{this.state.modalData.no_nota}</b>
                </p>
              )}
              {this.state.modalData.changed && (
                <p>
                  Perhatian!! Nomor dari nota yang anda buat telah diubah
                  otomatis oleh server.
                </p>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.move}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div className="main">
          {this.state.mainLoading && this.state.fetchProgress >= 0 && (
            <ModalLoading
              usepbar={true}
              msg={this.state.fetchMessage}
              progression={this.state.fetchProgress / this.totalProgress}
            />
          )}
          {this.state.timeout && <Timeout {...this.props} />}
          {this.state.notaData && (
            <FormNotaCom
              generateToast={this.generateToast}
              showModal={this.showModal}
              {...this.props}
              data={this.state.notaData}
              reset={(resetFunc) => this.setState({ resetForm: resetFunc })}
            />
          )}
        </div>
      </div>
    );
  }
}
FormNotaEdit.contextType = AuthContext;
export default FormNotaEdit;
