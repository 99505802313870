import {
  Form,
  Button,
  Row,
  Image as imagex,
  Col,
  Dropdown
} from "react-bootstrap";
import React, { Component } from "react";
import "../styles/Colour_Style.css";
import "../styles/Form_Style.css";
import { handleTextInput, handleNumberInput } from "../logics/FormInput_Logic";
import { editStatusNota } from "../logics/SubmitForm_Logic";
import { AuthContext } from "../context/Auth_Context";
import { formatNumber } from "../logics/FormatNumberPreview_Logic";
import * as jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";

class FormPengirimanCom extends Component {
  constructor(props, context) {
    super(props, context);
    if (props.data) {
      console.log(props.data);
      let date = new Date(props.data.tanggal_pengirim);
      let year = date.getFullYear();
      let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let month =
        date.getMonth() < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      props.data.tanggal_pengirim = day + "-" + month + "-" + year;

      if (props.data.site === null) props.data.site = "";

      let collectionMonth = [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AGS",
        "SEP",
        "OKT",
        "NOV",
        "DES"
      ];
      let collectionMonth2 = [
        "JANUARI",
        "FEBUARI",
        "MARET",
        "APRIL",
        "MAY",
        "JUNI",
        "JULI",
        "AGUSTUS",
        "SEPTEMBER",
        "OKTOBER",
        "NOVEMBER",
        "DESEMBER"
      ];
      props.data.tanggal_pengirim_2 =
        day + " " + collectionMonth[date.getMonth()] + " " + year;
      props.data.tanggal_pengirim_3 =
        day + " " + collectionMonth2[date.getMonth()] + " " + year;

      //FORMAT TUJUAN / SITE PER-25 KARAKTER
      let tp = props.data.tujuan_pengiriman.nama_perusahaan.split(" ");
      let res = [];
      let temp_str = "";
      let curr_len = 0;
      for (let i = 0; i < tp.length; i++) {
        if (curr_len + tp[i].length > 25) {
          if (tp[i].length > 25) {
            let diff_len = 25 - curr_len;
            temp_str += tp[i].substring(0, diff_len) + "-";
            tp[i] = tp[i].substring(diff_len, tp[i].length);
          }
          curr_len = 0;
          i = i - 1;
          res.push(temp_str);
          temp_str = "";
        } else {
          temp_str += tp[i] + " ";
          curr_len += tp[i].length;
        }
      }
      res.push(temp_str);
      props.data.nama_customer = res;
    }
    this.keterangan = this.props.data.keterangan || "";
    this.pemakaian = this.props.data.liter || 0;
    const { auth, setToken } = this.context;

    this.state = {
      auth: auth,
      setToken: setToken,
      error: false,
      errorMessage: "Input tidak boleh kosong"
    };

    this.cellBodyData = [];

    // Detail Nota ketika status belum selesai
    this.nota = new jsPDF("landscape", "pt", "a5");
    this.nota.setFontType("bold");
    this.nota.setFontSize(10);
    this.nota.text(65, 25, "PT. MIGAS KUKAR MANDIRI");
    this.nota.text(65, 38, "AGEN BBM PERTAMINA");
    this.nota.setFontSize(8);
    this.nota.setFontType("normal");
    this.nota.text(65, 50, "Jln Panglima Batur Kompleks Citra Niaga Blok B-12");
    this.nota.text(65, 60, "Samarinda 75112, Kalimantan Timur");
    this.nota.text(
      65,
      70,
      "Telp. (0541) 743717, Fax. (0541) 200743, Email : indotrans.sejahtera@gmail.com"
    );
    this.nota.setFontSize(15);
    this.nota.setFontType("bold");
    this.nota.text(65, 93, "SURAT JALAN PENGIRIMAN BBM");
    this.nota.setLineWidth(2);
    this.nota.line(65, 96, 309, 96);

    // this.nota.text(120, 118, "No :");
    // this.nota.setLineWidth(1);
    // this.nota.line(155, 121, 250, 121);
    // this.nota.text(252, 118, "( " + props.data.no_nota + " )");

    this.nota.setFontSize(11);
    this.nota.setFontType("normal");
    this.nota.text(370, 90, "Tgl pengiriman");
    this.nota.text(445, 90, ": " + props.data.tanggal_pengirim_2);

    this.nota.text(370, 105, "No. Pol Mobil");
    this.nota.text(445, 105, ": " + props.data.no_polisi);

    this.nota.text(370, 120, "Nama Driver");
    this.nota.text(445, 120, ": " + props.data.nama_supir);

    this.nota.text(370, 135, "No. PO");
    this.nota.text(445, 135, ": " + props.data.no_po);

    this.nota.text(370, 148, "Tujuan / Site");
    this.nota.text(445, 148, ":");
    var destination = this.nota.splitTextToSize(props.data.site, 160);
    var yOrig;
    for (let index = 0; index < destination.length; index++) {
      yOrig = 148 + index * 12;
      this.nota.text(450, yOrig, destination[index]);
    }
    // let y = 148;
    // for (let i = 0; i < props.data.nama_customer.length; i++) {
    //   this.nota.text(450, y, props.data.nama_customer[i]);
    //   y += 10;
    // }

    this.nota.setFontSize(11);
    this.nota.text(60, 155, "Kepada:");
    this.nota.text(
      60,
      170,
      props.data.tujuan_pengiriman.nama_perusahaan.toUpperCase() +
        ", " +
        props.data.tujuan_pengiriman.kota.toUpperCase()
    );
    // let temp = props.data.tujuan_pengiriman.nama_perusahaan.length + 2
    // this.nota.setFontSize(8);
    // this.nota.text(
    //   60+temp*6,
    //   175,
    //   props.data.tujuan_pengiriman.kota.toUpperCase()
    // );
    this.nota.autoTable({
      head: [["NO", "JENIS BARANG", "JUMLAH", "NO SEGEL", "KETERANGAN"]],
      body: [
        [
          "1",
          props.data.jenis_produk.jenis,
          formatNumber(props.data.kuantitas) + " LITER",
          "Atas: " +
            props.data.no_segel_atas +
            "\nBawah: " +
            props.data.no_segel_bawah,
          props.data.catatan
        ]
      ],
      startY: 180,
      theme: "grid",
      styles: {
        lineColor: [0, 0, 0],
        lineWidth: 1,
        fontSize: 9,
        minCellHeight: 60
      },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor:[0,0,0],
        halign: "center",
        valign: "middle",
        minCellHeight: 0
      },
      bodyStyles: {
        halign: "center",
        cellWidth: "wrap",
        textColor:[0,0,0]
      },
      columnStyles: {
        0: { cellWidth: 35.125 },
        1: { cellWidth: 116.239 },
        2: { cellWidth: 97.407 },
        3: { cellWidth: 118.795 },
        4: { cellWidth: 147.716 }
      },
      didDrawCell: data => {
        data.section === "body" && this.cellBodyData.push(data.cell);
      }
    });
    this.nota.setFontSize(8);
    this.nota.setFontType("bold");
    this.nota.text(40, this.nota.lastAutoTable.finalY + 15, "Perhatian !!");
    this.nota.setFontType("normal");
    this.nota.text(
      40,
      this.nota.lastAutoTable.finalY + 25,
      "Mohon untuk terlebih dahulu dicek kebenarannya sebelum ditandatangani Surat Jalan ini."
    );
    this.nota.text(
      40,
      this.nota.lastAutoTable.finalY + 35,
      "Kami tidak menerima claim atau tuntutan apapun jika BBM yang telah diserahkan sudah ditandatangani."
    );
    // this.nota.text(
    //   40,
    //   this.nota.lastAutoTable.finalY + 45,
    //   "Mohon untuk menandatangani dan menuliskan nama lengkap."
    // );
    this.nota.setFontType("bold");
    this.nota.setFontSize(9);
    this.nota.text(
      40,
      this.nota.lastAutoTable.finalY + 80 - 20,
      "Yang Menerima BBM,"
    );
    this.nota.text(
      40,
      this.nota.lastAutoTable.finalY + 140 - 20,
      "(                                  )"
    );

    // this.nota.text(
    //   252,
    //   this.nota.lastAutoTable.finalY + 80 - 20,
    //   "Driver Mobil / Nahkoda,"
    // );

    this.nota.text(
      "Driver Mobil Tangki,",
      295,
      this.nota.lastAutoTable.finalY  + 80 - 20,
      "center"
    );

    this.nota.text(
      250,
      this.nota.lastAutoTable.finalY + 140 - 20,
      "(                                  )"
    );
    this.nota.text(
      props.data.nama_supir,
      295,
      this.nota.lastAutoTable.finalY + 140 - 20,
      "center"
    );
    // this.nota.text(
    //   290 - (props.data.nama_supir.length / 2) * 4.5,
    //   this.nota.lastAutoTable.finalY + 140,
    //   props.data.nama_supir
    // );

    this.nota.text(477, this.nota.lastAutoTable.finalY + 80 - 20, "Dibuat Oleh,");
    this.nota.text(
      460,
      this.nota.lastAutoTable.finalY + 140 - 20,
      "(                                  )"
    );
    this.nota.text(
      props.data.idAdmin.nama,
      508,
      this.nota.lastAutoTable.finalY + 140 - 20,
      "center"
    );
    // this.nota.text(
    //   500 - (props.data.idAdmin.nama.length / 2) * 4.5,
    //   this.nota.lastAutoTable.finalY + 140,
    //   props.data.idAdmin.nama
    // );
  }

  handleChange = event => {
    if (event.target.name === "keterangan")
      this.keterangan = event.target.value;
    else {
      let val = handleNumberInput(event);
      val = val ? parseInt(val, 10) : "";
      this.pemakaian = val;
      this.setState({
        error: val ? val === 0 : true,
        errorMessage:
          val === 0 ? "Input harus lebih dari 0" : "Input tidak boleh kosong"
      });
    }
  };

  submit = () => {
    if (this.pemakaian < 1)
      this.setState({
        error: true,
        errorMessage:
          this.pemakaian === 0
            ? "Input harus lebih dari 0"
            : "Input tidak boleh kosong"
      });
    else !this.state.error && this.props.showModal();
  };

  doSubmit = async () => {
    this.props.setLoading();
    let success = false;
    let response = await editStatusNota(
      this.state.auth.token,
      this.props.data._id,
      this.keterangan,
      this.pemakaian,
      this.props.data.no_nota
    );
    if (response[0] && response[0].status === 200) {
      response[0].token && this.state.setToken(response[0].token);
      success = true;
    } else if (response[0] && response[0].status === 402) {
      let path = "/";
      this.props.history.push({
        pathname: path,
        state:
          "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi."
      });
    }
    this.props.setSuccess(success);
  };

  componentDidMount() {
    // Gambar untuk detail status sudah selesai
    // this.props.setSave(this.doSubmit);
    // var logo = document.getElementById("logo_pt");
    //this.nota.addImage(logo, "svg", 500, 15, 53, 64);

    // Gambar untuk Nota
    this.props.setSave(this.doSubmit);
    var logo = document.getElementById("logo_pt");
    var pertamina = document.getElementById("logo_pertamina");

    this.nota.addImage(logo, "JPEG", 10, 15, 53, 36);
    this.nota.addImage(pertamina, "JPEG", 405, 25, 160, 38);
  }

  printLoadingOrder = async () => {
    let nota = new jsPDF("portrait", "pt", " ");
    nota.setDrawColor(0);
    nota.setFontSize(15);
    nota.text(42, 50, "LOADING ORDER");
    nota.setLineWidth(1.2);
    nota.line(42, 54, 168, 54);
    nota.setFontSize(11);
    nota.setFontStyle("bold");
    //nota.text(this.props.data.asal_pengiriman.nama, 550, 52, "right");
    nota.setFontStyle("normal");
    nota.setFontSize(9);
    nota.text(42, 67, "Tanggal : " + this.props.data.tanggal_pengirim_2);
    nota.text(42, 97, "Kepada : ");
    nota.setFontSize(12);
    nota.setFontStyle("bold");
    nota.text(42, 112, this.props.data.tujuan_pengiriman.nama_perusahaan);
    nota.setFontStyle("normal");
    nota.setFontSize(9);
    nota.text(42, 125, this.props.data.tujuan_pengiriman.alamat);
    nota.text(
      42,
      137,
      this.props.data.tujuan_pengiriman.kota +
        (this.props.data.tujuan_pengiriman.kode_pos
          ? ", " + this.props.data.tujuan_pengiriman.kode_pos
          : "")
    );
    nota.text(42, 165, "Tujuan / Lokasi");
    nota.text(42, 177, "Loading Port");
    nota.text(42, 189, "Transportir");
    nota.text(108, 165, ": " + this.props.data.site);
    nota.text(108, 177, ": " + this.props.data.titik_pengambilan.nama);
    nota.text(108, 189, ": " + this.props.data.asal_pengiriman.nama);

    nota.text(320, 165, "Data Armada");
    nota.text(320, 177, "Nama Driver / Nahkoda");
    nota.text(320, 189, "Nomor Polisi");
    nota.text(320, 201, "No Hp");

    nota.text(
      420,
      177,
      ": " + (this.props.data.nama_supir ? this.props.data.nama_supir : "-")
    );
    nota.text(
      420,
      189,
      ": " + (this.props.data.no_polisi ? this.props.data.no_polisi : "-")
    );
    nota.text(
      420,
      201,
      ": " + (this.props.data.no_hp_supir ? this.props.data.no_hp_supir : "-")
    );
    nota.text(42, 235, "Shipping details");
    nota.text(42, 247, "No");
    nota.text(108, 247, "Keterangan");
    nota.text(320, 247, "Quantity");
    nota.text(450, 247, "No Segel");
    nota.setLineWidth(0.8);
    nota.line(42, 252, 553, 252);
    nota.text(42, 270, "1");
    nota.text(108, 270, this.props.data.jenis_produk.jenis);
    nota.text(108, 282, "Reference PO : " + this.props.data.no_po);
    nota.text(108, 294, "Nomor Nota : " + this.props.data.no_nota);
    nota.text(320, 270, formatNumber(this.props.data.kuantitas) + " LITER");
    nota.text(450, 270, this.props.data.no_segel_atas);
    nota.text(450, 282, this.props.data.no_segel_bawah);
    window.open(nota.output("bloburl"));
  };

  printNotaBlank = async () => {
    var nota = new jsPDF("landscape", "pt", "a5");
    nota.setFontSize(15);
    nota.setFontType("bold");
    nota.text(65, 93, "SURAT JALAN PENGIRIMAN BBM");
    nota.setLineWidth(2);
    nota.line(65, 96, 309, 96);

    nota.text(120, 118, "No :");
    nota.setLineWidth(1);
    nota.text(155, 118, this.props.data.no_nota);

    nota.setFontSize(11);
    nota.setFontType("normal");
    nota.text(370, 90, "Tgl pengiriman");
    nota.text(445, 90, ": " + this.props.data.tanggal_pengirim_2);

    nota.text(370, 105, "No. Pol Mobil");
    nota.text(445, 105, ": " + this.props.data.no_polisi);

    nota.text(370, 120, "Nama Driver");
    nota.text(445, 120, ": " + this.props.data.nama_supir);

    nota.text(370, 135, "No. PO");
    nota.text(445, 135, ": " + this.props.data.no_po);

    nota.text(370, 148, "Tujuan / Site");
    nota.text(445, 148, ":");
    var destination = nota.splitTextToSize(this.props.data.site, 160);
    var yOrig;
    for (let index = 0; index < destination.length; index++) {
      yOrig = 148 + index * 12;
      nota.text(450, yOrig, destination[index]);
    }

    nota.setFontSize(11);
    nota.text(60, 155, "Kepada:");
    nota.text(
      60,
      170,
      this.props.data.tujuan_pengiriman.nama_perusahaan.toUpperCase() +
        ", " +
        this.props.data.tujuan_pengiriman.kota.toUpperCase()
    );
    var cellBodyData = [];
    nota.autoTable({
      head: [["NO", "JENIS BARANG", "JUMLAH", "NO SEGEL", "KETERANGAN"]],
      body: [
        [
          "1",
          this.props.data.jenis_produk.jenis,
          formatNumber(this.props.data.kuantitas) + " LITER",
          "Atas: " +
            this.props.data.no_segel_atas +
            "\nBawah: " +
            this.props.data.no_segel_bawah,
          this.props.data.catatan
        ]
      ],
      startY: 180,
      theme: "grid",
      styles: {
        lineColor: [0, 0, 0],
        lineWidth: 1,
        fontSize: 9,
        minCellHeight: 60
      },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor:[0,0,0],
        halign: "center",
        valign: "middle",
        minCellHeight: 0
      },
      bodyStyles: {
        halign: "center",
        cellWidth: "wrap",
        textColor:[0,0,0]
      },
      columnStyles: {
        0: { cellWidth: 35.125 },
        1: { cellWidth: 116.239 },
        2: { cellWidth: 97.407 },
        3: { cellWidth: 118.795 },
        4: { cellWidth: 147.716 }
      },
      didDrawCell: data => {
        data.section === "body" && cellBodyData.push(data.cell);
      }
    });
    nota.setFontSize(8);
    nota.setFontType("bold");
    nota.text(40, this.nota.lastAutoTable.finalY + 15, "Perhatian !!");
    nota.setFontType("normal");
    nota.text(
      40,
      nota.lastAutoTable.finalY + 25,
      "Mohon untuk terlebih dahulu dicek kebenarannya sebelum ditandatangani Surat Jalan ini."
    );
    nota.text(
      40,
      nota.lastAutoTable.finalY + 35,
      "Kami tidak menerima claim atau tuntutan apapun jika BBM yang telah diserahkan sudah ditandatangani."
    );
    // nota.text(
    //   40,
    //   nota.lastAutoTable.finalY + 45,
    //   "Mohon untuk menandatangani dan menuliskan nama lengkap."
    // );
    nota.setFontType("bold");
    nota.setFontSize(9);
    nota.text(40, nota.lastAutoTable.finalY + 80 - 20, "Yang Menerima BBM,");
    nota.text(
      40,
      nota.lastAutoTable.finalY + 140 - 20,
      "(                                  )"
    );

    //nota.text(252, nota.lastAutoTable.finalY + 80 - 20, "Driver Mobil Tangki,");
    nota.text(
      "Driver Mobil Tangki,",
      295,
      nota.lastAutoTable.finalY  + 80 - 20,
      "center"
    );
    nota.text(
      250,
      nota.lastAutoTable.finalY + 140 - 20,
      "(                                  )"
    );
    nota.text(
      this.props.data.nama_supir,
      295,
      nota.lastAutoTable.finalY + 140 - 20,
      "center"
    );

    nota.text(477, nota.lastAutoTable.finalY + 80 - 20, "Dibuat Oleh,");
    nota.text(
      460,
      nota.lastAutoTable.finalY + 140 - 20,
      "(                                  )"
    );
    nota.text(
      this.props.data.idAdmin.nama,
      508,
      nota.lastAutoTable.finalY + 140 - 20,
      "center"
    );
    window.open(nota.output("bloburl"));
  };

  printDeliveryOrder = async () => {
    let nota = new jsPDF("portrait", "pt", " ");
    nota.setFontSize(15);
    nota.text(42, 185, "SURAT JALAN PENGIRIMAN BBM");
    nota.line(42, 188, 280, 188);
    nota.setFontSize(14);
    nota.text(42, 205, "DELIVERY ORDER");
    nota.setFontSize(14);
    nota.text(320, 185, "No : " + this.props.data.no_nota);

    nota.setFontSize(11);
    nota.text(42, 240, "Transportir:");
    nota.setFontStyle("bold");
    nota.setFontSize(12);
    nota.text(42, 255, this.props.data.asal_pengiriman.nama);
    nota.setFontStyle("normal");
    nota.setFontSize(11);
    var origin = nota.splitTextToSize(
      this.props.data.asal_pengiriman.alamat,
      250
    );
    nota.text(42, 268, origin[0]);
    var yOrig;
    for (let index = 0; index < origin.length; index++) {
      yOrig = 268 + index * 12;
      nota.text(42, yOrig, origin[index]);
    }
    nota.text(
      42,
      yOrig + 12,
      this.props.data.asal_pengiriman.kota +
        (this.props.data.asal_pengiriman.kode_pos
          ? ", " + this.props.data.asal_pengiriman.kode_pos
          : "")
    );

    nota.setFontStyle("bold");
    nota.text(42, 320, "Data Armada:");
    nota.setFontStyle("normal");
    nota.text(42, 333, "Nama Driver / Nahkoda");
    nota.text(42, 345, "Nomor Polisi");
    nota.text(42, 357, "No Hp");
    nota.text(42, 369, "Jenis Kendaraan");
    nota.text(42, 381, "Loading port");

    nota.text(
      159,
      333,
      ": " + (this.props.data.nama_supir ? this.props.data.nama_supir : "-")
    );
    nota.text(
      159,
      345,
      ": " + (this.props.data.no_polisi ? this.props.data.no_polisi : "-")
    );
    nota.text(
      159,
      357,
      ": " + (this.props.data.no_hp_supir ? this.props.data.no_hp_supir : "-")
    );
    nota.text(
      159,
      369,
      ": " +
        (this.props.data.kendaraanId.jenis_kendaraan
          ? this.props.data.kendaraanId.jenis_kendaraan
          : "-")
    );
    nota.text(
      159,
      381,
      ": " +
        (this.props.data.titik_pengambilan.nama
          ? this.props.data.titik_pengambilan.nama
          : "-")
    );

    nota.text(320, 240, "Pemilik Barang:");
    nota.setFontStyle("bold");
    nota.setFontSize(12);
    nota.text(320, 255, this.props.data.tujuan_pengiriman.nama_perusahaan);
    nota.setFontStyle("normal");
    nota.setFontSize(11);
    var destination = nota.splitTextToSize(
      this.props.data.tujuan_pengiriman.alamat,
      250
    );
    nota.text(320, 268, destination[0]);
    var yDest;
    for (let index = 0; index < destination.length; index++) {
      yDest = 268 + index * 12;
      nota.text(320, yDest, destination[index]);
    }
    nota.setFontSize(11);
    nota.text(
      320,
      yDest + 12,
      this.props.data.tujuan_pengiriman.kota +
        (this.props.data.tujuan_pengiriman.kode_pos
          ? ", " + this.props.data.tujuan_pengiriman.kode_pos
          : "")
    );

    nota.text(320, 333, "No.PO");
    nota.text(320, 345, "Contact Person");
    nota.text(320, 357, "NoTelp / HP");
    nota.text(320, 381, "Tujuan / Site");

    nota.text(
      398,
      333,
      ": " + (this.props.data.no_po ? this.props.data.no_po : "-")
    );
    nota.text(
      398,
      345,
      ": " + (this.props.data.pj_penerima ? this.props.data.pj_penerima : "-")
    );
    nota.text(
      398,
      357,
      ": " +
        (this.props.data.tujuan_pengiriman.no_hp
          ? this.props.data.tujuan_pengiriman.no_hp
          : "-")
    );
    nota.text(398, 381, ": ");
    nota.setFontStyle("bold");
    nota.text(404, 381, this.props.data.site ? this.props.data.site : "-");

    // Table
    nota.autoTable({
      head: [["No", "Jenis Barang", "Quantity", "No Segel", "Keterangan"]],
      body: [
        [
          1,
          this.props.data.jenis_produk.jenis,
          formatNumber(this.props.data.kuantitas) + " LITER",
          "Atas" +
            "       : " +
            this.props.data.no_segel_atas +
            "\nBawah" +
            "   : " +
            this.props.data.no_segel_bawah,
          this.props.data.catatan
        ]
      ],
      startY: 410,
      theme: "grid",
      styles: {
        lineColor: [0, 0, 0],
        lineWidth: 1,
        fontSize: 11,
        minCellHeight: 80
      },
      headStyles: {
        textColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        halign: "center",
        valign: "middle",
        minCellHeight: 0
      },
      bodyStyles: {
        textColor: [0, 0, 0],
        halign: "left",
        cellWidth: "wrap",
        fontSize: 12
      },
      columnStyles: {
        0: { cellWidth: 30 },
        1: { cellWidth: 125 },
        2: { cellWidth: 97.407 },
        3: { cellWidth: 97.407 },
        4: { cellWidth: 170 }
      }
    });

    // Footer
    nota.setFontSize(11);
    nota.setFontStyle("bold");
    nota.text(
      39,
      nota.lastAutoTable.finalY + 28,
      "Tanggal: " + this.props.data.tanggal_pengirim_3
    );
    nota.setFontStyle("normal");
    nota.setFontSize(11);
    nota.text(40, nota.lastAutoTable.finalY + 90, "Yang Menerima BBM,");
    nota.text(
      40,
      nota.lastAutoTable.finalY + 170,
      "(                                  )"
    );

    nota.text(252, nota.lastAutoTable.finalY + 90, "Driver Mobil / Nahkoda,");
    nota.text(
      250,
      nota.lastAutoTable.finalY + 170,
      "(                                  )"
    );
    nota.text(
      this.props.data.nama_supir,
      304,
      nota.lastAutoTable.finalY + 170,
      "center"
    );
    nota.text(477, nota.lastAutoTable.finalY + 90, "Dibuat Oleh,");
    nota.text(
      450,
      nota.lastAutoTable.finalY + 170,
      "(                                     )"
    );
    nota.text(
      this.props.data.idAdmin.nama,
      512,
      nota.lastAutoTable.finalY + 170,
      "center"
    );

    window.open(nota.output("bloburl"));
  };

  printBerita = async () => {
    var coor = {
      x: 0,
      y: 0
    };

    let nota = new jsPDF("portrait", "pt", " ");
    nota.setFontSize(15);
    nota.text(
      "BERITA ACARA PENERIMAAN BBM",
      nota.internal.pageSize.width / 2,
      185,
      null,
      null,
      "center"
    );
    nota.setLineWidth(2);
    coor.x = 175;
    coor.y = 188;
    nota.line(coor.x, coor.y, coor.x + 249, coor.y);
    nota.setFontSize(13);
    nota.text(coor.x + 300, coor.y - 45, "No. " + this.props.data.no_nota);
    nota.setFontSize(12);
    nota.text(
      coor.x,
      coor.y + 20,
      "Referensi No.PO : " + this.props.data.no_po
    );
    nota.text(
      coor.x + 100,
      coor.y + 23,
      ".........................................."
    );
    nota.setFontSize(10);
    nota.text(
      coor.x - 135,
      coor.y + 45,
      "Telah dibuat Berita Acara Penerimaan BBM dengan detail sebagai berikut :"
    );
    var coorForm = {
      x: 0,
      y: 0
    };
    coorForm.x = coor.x - 77;
    coorForm.y = coor.y + 69;
    var lineForm = coorForm.y;
    var colForm = coorForm.x + 220;
    nota.text(coorForm.x, lineForm, "Hari / Tanggal");
    nota.text(coorForm.x, (lineForm += 18), "Jam / Pukul");
    nota.text(
      coorForm.x,
      (lineForm += 18),
      "Perusahaan Penyuplai / Transportir"
    );
    nota.text(colForm + 6, lineForm, this.props.data.asal_pengiriman.nama);
    nota.text(coorForm.x, (lineForm += 18), "No. Polisi Mobil Tangki");
    nota.text(colForm + 6, lineForm, this.props.data.no_polisi);
    nota.text(coorForm.x, (lineForm += 18), "Jenis BBM");
    nota.text(colForm + 6, lineForm, this.props.data.jenis_produk.jenis);
    nota.text(coorForm.x, (lineForm += 18), "Lokasi Bongkar / Site");
    nota.text(colForm + 6, lineForm, this.props.data.site);
    nota.text(
      coorForm.x,
      (lineForm += 18),
      "Hasil Sonding di site / Flowmeter"
    );
    nota.text(coorForm.x, (lineForm += 18), "Temperatur (°C)");
    nota.text(coorForm.x, (lineForm += 18), "Density");
    nota.text(coorForm.x, (lineForm += 18), "No.Segel");
    nota.text(colForm + 20, lineForm, this.props.data.no_segel_atas);
    nota.text(colForm + 20, lineForm + 18, this.props.data.no_segel_bawah);
    nota.text(
      coorForm.x,
      (lineForm += 36),
      "Tinggi Permukaan Solar (Surat Jalan)"
    );
    nota.text(
      coorForm.x,
      (lineForm += 18),
      "Tinggi Permukaan Solar (Saat Bongkar)"
    );
    nota.text(coorForm.x, (lineForm += 18), "Jumlah Liter (Surat Jalan)");
    nota.text(colForm + 6, lineForm, formatNumber(this.props.data.kuantitas));
    nota.text(coorForm.x, (lineForm += 18), "Jumlah Liter (Setelah Bongkar)");
    nota.text(coorForm.x, (lineForm += 18), "Catatan / Keterangan");
    lineForm = coorForm.y;
    //Content
    nota.text(colForm, lineForm, ":");
    nota.text(
      colForm + 3,
      lineForm + 3,
      "......................../......................................."
    );
    for (let index = 0; index < 15; index++) {
      if (index == 8 || index == 9) {
        nota.text(colForm, (lineForm += 18), index == 8 ? ": 1." : "  2.");
        nota.text(colForm + 18, lineForm + 3, ".....................");
        nota.text(colForm + 80, lineForm, index == 8 ? "(Atas)" : "(Bawah)");
      } else if (index > 9 && index < 14) {
        nota.text(colForm, (lineForm += 18), ":");
        nota.text(colForm + 3, lineForm + 3, ".....................");
        nota.text(colForm + 64, lineForm, index > 11 ? "Liter" : "Cm");
      } else if (index == 14) {
        nota.text(colForm, (lineForm += 18), ":");
      } else {
        nota.text(colForm, (lineForm += 18), ":");
        nota.text(
          colForm + 3,
          lineForm + 3,
          "................................................................"
        );
      }
    }
    nota.setLineWidth(0.8);
    nota.rect(colForm + 5, lineForm - 5, 230, 80);
    nota.text(
      coor.x - 135,
      (lineForm += 98),
      "Demikian Berita Acara ini dibuat dengan disaksikan dan disetujui perwakilan kedua belah pihak."
    );
    nota.text(
      coor.x - 135,
      (lineForm += 50),
      this.props.data.site + ", ..............................20........"
    );
    //nota.text(coor.x - 135, lineForm += 50,this.props.data.tujuan_pengiriman.kota+ ", ..............................20........");

    nota.text(40, lineForm + 30, "Yang Menerima BBM,");
    nota.text(40, lineForm + 110, "(                                  )");

    nota.text(252, lineForm + 30, "Driver Mobil / Nahkoda,");
    nota.text(250, lineForm + 110, "(                                  )");

    nota.text(this.props.data.nama_supir, 304, lineForm + 110, "center");
    nota.text(477, lineForm + 30, "Dibuat Oleh,");
    nota.text(450, lineForm + 110, "(                                     )");
    nota.text(this.props.data.idAdmin.nama, 505, lineForm + 110, "center");

    window.open(nota.output("bloburl"));
  };

  printNota = isCounter => {
    var nota = new jsPDF("potrait", "pt", "a4");

    nota.setFontSize(11);
    nota.setFontType("normal");
    nota.text(451, 90, this.props.data.tanggal_pengirim_2);

    nota.text(451, 105, this.props.data.no_polisi);

    nota.text(451, 120, this.props.data.nama_supir);

    nota.text(451, 135, this.props.data.no_po);

    var destination = nota.splitTextToSize(this.props.data.site, 160);
    var yOrig;
    for (let index = 0; index < destination.length; index++) {
      yOrig = 148 + index * 12;
      nota.text(450, yOrig, destination[index]);
    }

    if (isCounter) {
      nota.setFontSize(15);
      nota.setFontType("bold");
      nota.text(65, 93, "SURAT JALAN PENGIRIMAN BBM");
      nota.setLineWidth(2);
      nota.line(65, 96, 309, 96);

      nota.text(120, 118, "No :");
      nota.setLineWidth(1);
      //nota.line(155, 121, 250, 121);
      nota.text(155, 118, this.props.data.no_nota);

      // let y = 148;
      // for (let i = 0; i < this.props.data.nama_customer.length; i++) {
      //   nota.text(450, y, this.props.data.nama_customer[i]);
      //   y += 10;
      // }
    }

    nota.setFontSize(11);
    nota.text(60, 155, "Kepada:");
    nota.text(
      60,
      170,
      this.props.data.tujuan_pengiriman.nama_perusahaan.toUpperCase() +
        ", " +
        this.props.data.tujuan_pengiriman.kota.toUpperCase()
    );
    // let temp = this.props.data.tujuan_pengiriman.nama_perusahaan.length + 2
    // console.log(temp)
    // nota.setFontSize(8);
    // nota.text(
    //   60+temp*6,
    //   175,
    //   this.props.data.tujuan_pengiriman.kota.toUpperCase()
    // );

    nota.setFontSize(9);
    this.cellBodyData.forEach(element => {
      let temp = "";
      element.text.forEach(text => {
        temp = temp + text + "\n";
      });
      temp = temp.substring(0, temp.length - 1);
      let test =
        element.width > element.contentWidth
          ? element.contentWidth
          : element.contentWidth / element.text.length;
      let alpha = element.width > element.contentWidth ? 5 : 0;
      nota.text(
        element.x + (element.width - test) / 2 + alpha,
        element.y + 12.5,
        temp
      );
    });

    // nota.setFontType("bold");
    // nota.text(
    //   290 - (this.props.data.nama_supir.length / 2) * 4.5,
    //   this.nota.lastAutoTable.finalY + 140,
    //   this.props.data.nama_supir
    // );
    nota.text(
      this.props.data.nama_supir,
      295,
      this.nota.lastAutoTable.finalY + 140,
      "center"
    );

    // nota.text(
    //   500 - (this.props.data.idAdmin.nama.length / 2) * 4.5,
    //   this.nota.lastAutoTable.finalY + 140,
    //   this.props.data.idAdmin.nama
    // );
    nota.text(
      this.props.data.idAdmin.nama,
      508,
      this.nota.lastAutoTable.finalY + 140,
      "center"
    );

    nota.autoPrint();
    window.open(nota.output("bloburl"));
  };

  previewPrint = () => {
    window.open(this.nota.output("bloburl"));
  };

  getDataUri = url => {
    return new Promise(resolve => {
      var image = new Image();
      image.setAttribute("crossOrigin", "anonymous"); //getting images from external domain

      image.onload = function() {
        var canvas = document.createElement("canvas");
        canvas.width = this.naturalWidth;
        canvas.height = this.naturalHeight;

        //next three lines for white background in case png has a transparent background
        var ctx = canvas.getContext("2d");
        ctx.fillStyle = "rgba(255, 255, 255, 0)"; /// set white fill style
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        canvas.getContext("2d").drawImage(this, 0, 0);

        resolve(canvas.toDataURL());
      };

      image.src = url;
    });
  };

  printWhenDone = async () => {
    var header =
      localStorage.perusahaan !== "undefined" || !localStorage.perusahaan
        ? localStorage.perusahaan
        : "PT.MIGAS KUKAR MANDIRI";
    var logo = null;
    if (localStorage.logo !== "undefined" || !localStorage.logo) {
      logo = await this.getDataUri(localStorage.logo);
    } else {
      logo = document.getElementById("logo_pt");
    }
    // jsPDF untuk Detail Pengiriman ketika status sudah selesai
    let nota = new jsPDF("portrait", "pt", " ");
    nota.setDrawColor(0);
    nota.setFillColor(233, 233, 233);
    nota.rect(0, 0, 595, 93.613, "F");
    nota.setFontSize(20);
    // Header
    nota.text(42, 35, header);
    nota.setFontSize(14);
    nota.text(42, 70, "Detail Pengiriman");
    nota.addImage(logo, "JPEG", 500, 15, 64, 64);
    // End Of Header

    // Left Data
    nota.setFontSize(12);
    nota.setFontStyle("bold");
    nota.text(42, 140, "Perusahaan");
    nota.text(42, 160, "Pemilik Kendaraan");
    nota.text(42, 195, "Nama Supir / Nahkoda");
    nota.text(42, 230, "No HP");
    nota.text(42, 265, "Jenis Kendaraan");

    nota.setFontStyle("normal");
    nota.text(174, 145, ": " + this.props.data.perusahaan_pemiliki_kendaraan);
    nota.text(174, 195, ": " + this.props.data.nama_supir);
    nota.text(174, 230, ": " + this.props.data.no_hp_supir);
    nota.text(174, 265, ": " + this.props.data.nama_kendaraan);
    // End Of Left Data

    // Right Data
    nota.setFontStyle("bold");
    nota.text(390, 140, "Tanggal");
    nota.text(390, 155, "Pengiriman");
    nota.text(390, 195, "No PO");
    nota.text(390, 230, "No Nota");
    nota.text(390, 245, "Pengiriman");
    nota.text(390, 265, "No Polisi");
    nota.text(390, 290, "Site");
    nota.setFontStyle("normal");
    nota.text(468, 145, ": " + this.props.data.tanggal_pengirim);
    let no_po = nota.splitTextToSize(this.props.data.no_po, 85);
    let yOrig;
    for (let index = 0; index < no_po.length; index++) {
      yOrig = 195 + index * 12;
      nota.text(468, yOrig, no_po[index]);
    }
    // nota.text(468, 195, ": " + this.props.data.no_po);
    nota.text(468, 235, ": " + this.props.data.no_nota);
    nota.text(468, 265, ": " + this.props.data.no_polisi);
    nota.text(468, 290, ": " + this.props.data.site);
    // End Of Right Data

    // Lines
    nota.setDrawColor(193, 39, 45);
    nota.setLineWidth(2);
    nota.line(42, 320, 553, 320);
    // End Of Lines

    // Asal Pengiriman, Titik Pengambilan Barang, Tujuan Pengiriman
    nota.setFontStyle("bold");
    nota.text(42, 375, "Consignee");
    nota.text(140, 375, this.props.data.tujuan_pengiriman.nama_perusahaan);
    // nota.text(435, 375, "Tujuan Pengiriman");
    nota.setFontStyle("normal");
    // nota.text(42, 395, "" + this.props.data.asal_pengiriman.nama);
    nota.text(140, 395, "" + this.props.data.tujuan_pengiriman.alamat);
    // nota.text(435, 395, "" + this.props.data.tujuan_pengiriman.nama_perusahaan);
    // End Of Asal Pengiriman, Titik Pengambilan Barang, Tujuan Pengiriman

    // Table
    nota.autoTable({
      head: [["JENIS PRODUK", "JUMLAH", "NO SEGEL", "KETERANGAN"]],
      body: [
        [
          this.props.data.jenis_produk.jenis,
          formatNumber(this.props.data.kuantitas) + " LITER",
          "Atas" +
            "       : " +
            this.props.data.no_segel_atas +
            "\nBawah" +
            "   : " +
            this.props.data.no_segel_bawah,
          this.props.data.catatan
        ]
      ],
      startY: 420,
      theme: "grid",
      styles: {
        lineColor: [0, 0, 0],
        lineWidth: 1,
        fontSize: 12,
        minCellHeight: 80
      },
      headStyles: {
        fillColor: [193, 39, 45],
        halign: "center",
        valign: "middle",
        minCellHeight: 0
      },
      bodyStyles: {
        halign: "left",
        cellWidth: "wrap",
        fontSize: 12
      },
      columnStyles: {
        0: { cellWidth: 125 },
        1: { cellWidth: 97.407 },
        2: { cellWidth: 97.407 },
        3: { cellWidth: 200 }
      },
      didDrawCell: data => {
        data.section === "body" && this.cellBodyData.push(data.cell);
      }
    });
    // End Of Table

    // Footer
    nota.setFontStyle("bold");
    nota.text(42, nota.lastAutoTable.finalY + 50, "Biaya Perjalanan");
    nota.text(42, nota.lastAutoTable.finalY + 130, "Catatan Tambahan");
    nota.text(42, nota.lastAutoTable.finalY + 240, "Pemakaian (Liter)");
    nota.setFontType("normal");
    nota.text(
      42,
      nota.lastAutoTable.finalY + 70,
      "" + this.props.data.biaya_penyebrangan
    );
    nota.text(
      46,
      nota.lastAutoTable.finalY + 155,
      "" + this.props.data.keterangan
    );
    nota.rect(42, nota.lastAutoTable.finalY + 140, 520, 70);
    nota.text(42, nota.lastAutoTable.finalY + 260, "" + this.pemakaian);
    // End Of Footer

    window.open(nota.output("bloburl"));
  };

  render() {
    const style = {
      maxWidth: "200px",
      minWidth: "200px",
      maxHeight: "80px",
      minHeight: "80px"
    };

    const consigneeStyle = {
      minWidth: "200px",
      minHeight: "50px"
    };
    return (
      <div className="container delivery-form">
        {this.props.data.status === 1 ? (
          <h4>Status Pengiriman: sedang dalam perjalanan</h4>
        ) : (
          <h4>Status Pengiriman: selesai</h4>
        )}
        <img id="logo_pt" src="assets/logo/mkm-transparent.png" hidden />
        <img id="logo_pertamina" src="assets/logo/pertamina.png" hidden />
        <Row id="row-pengiriman">
          <div className="logo-container-pengiriman">
            <Row>
              <Col sm={8} style={{ display: "grid", alignItems: "center" }}>
                <h3>
                  {localStorage.perusahaan !== "undefined" ||
                  !localStorage.perusahaan
                    ? localStorage.perusahaan
                    : "PT.MIGAS KUKAR MANDIRI"}
                </h3>
                <h5>Detail Pengiriman (Delivery Order)</h5>
              </Col>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end"
                }}
                sm={4}
              >
                <img
                  className="logo-mkm-pengiriman"
                  src={
                    localStorage.logo !== "undefined" || !localStorage.logo
                      ? localStorage.logo
                      : "assets/logo/mkm-transparent.png"
                  }
                />
              </Col>
            </Row>
          </div>
          <Form className="form-pengiriman">
            <Row>
              {/* <Col xs={12} md={8}> */}
              <Col xs={12} md={7}>
                <Form.Group as={Row}>
                  <Form.Label column xs={5} className="label-pengiriman">
                    Perusahaan Pemilik Armada{" "}
                  </Form.Label>
                  <Col xs={7}>
                    <Form.Control
                      plaintext
                      readOnly
                      defaultValue={
                        ": " + this.props.data.perusahaan_pemiliki_kendaraan
                      }
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column xs={5} className="label-pengiriman">
                    Nama Supir / Nakhoda{" "}
                  </Form.Label>
                  <Col xs={7}>
                    <Form.Control
                      plaintext
                      readOnly
                      defaultValue={": " + this.props.data.nama_supir}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column xs={5} className="label-pengiriman">
                    No HP
                  </Form.Label>
                  <Col xs={7}>
                    <Form.Control
                      plaintext
                      readOnly
                      defaultValue={": " + this.props.data.no_hp_supir}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column xs={5} className="label-pengiriman">
                    Jenis Kendaraan{" "}
                  </Form.Label>
                  <Col xs={7}>
                    <Form.Control
                      plaintext
                      readOnly
                      defaultValue={": " + this.props.data.nama_kendaraan}
                    />
                  </Col>
                </Form.Group>
              </Col>
              {/* <Col xs={6} md={4}> */}
              <Col xs={12} md={5}>
                <Form.Group as={Row}>
                  <Form.Label column xs={5} className="label-pengiriman">
                    Tanggal Pengiriman
                  </Form.Label>
                  <Col xs={7}>
                    <Form.Control
                      plaintext
                      readOnly
                      defaultValue={": " + this.props.data.tanggal_pengirim}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column xs={5} className="label-pengiriman">
                    No PO
                  </Form.Label>
                  <Col xs={7}>
                    <Form.Control
                      plaintext
                      readOnly
                      defaultValue={": " + this.props.data.no_po}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column xs={5} className="label-pengiriman">
                    No Nota Pengiriman
                  </Form.Label>
                  <Col xs={7}>
                    <Form.Control
                      plaintext
                      readOnly
                      defaultValue={": " + this.props.data.no_nota}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column xs={5} className="label-pengiriman">
                    No Polisi
                  </Form.Label>
                  <Col xs={7}>
                    <Form.Control
                      plaintext
                      readOnly
                      defaultValue={": " + this.props.data.no_polisi}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column xs={5} className="label-pengiriman">
                    Site
                  </Form.Label>
                  <Col xs={7}>
                    <Form.Control
                      plaintext
                      readOnly
                      defaultValue={": " + this.props.data.site}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row style={{ borderTop: "2px solid #f47121", paddingTop: "10px" }}>
              <Col>
                <Form.Group as={Row}>
                  <Form.Label column sm="2" className="label-pengiriman">
                    Consignee
                  </Form.Label>
                  <Col>
                    <Form.Control
                      plaintext
                      readOnly
                      style={consigneeStyle}
                      sm="10"
                      as="textarea"
                      defaultValue={
                        this.props.data.tujuan_pengiriman.nama_perusahaan +
                        "\n" +
                        this.props.data.tujuan_pengiriman.alamat
                      }
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Col className="col-table">
                <Form.Group>
                  <Form.Label className="label-pengiriman">
                    Jenis Produk
                  </Form.Label>
                  <Form.Control
                    plaintext
                    readOnly
                    defaultValue={this.props.data.jenis_produk.jenis}
                  />
                </Form.Group>
              </Col>
              <Col className="col-table">
                <Form.Group>
                  <Form.Label className="label-pengiriman">Jumlah</Form.Label>
                  <Form.Control
                    plaintext
                    readOnly
                    defaultValue={
                      formatNumber(this.props.data.kuantitas) + " LITER"
                    }
                  />
                </Form.Group>
              </Col>
              <Col className="col-table">
                <Form.Group>
                  <Form.Label className="label-pengiriman">No Segel</Form.Label>
                  <Row>
                    <Form.Label column sm={4}>
                      Atas
                    </Form.Label>
                    <Col>
                      <Form.Control
                        plaintext
                        readOnly
                        defaultValue={": " + this.props.data.no_segel_atas}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Form.Label column sm={4}>
                      Bawah
                    </Form.Label>
                    <Col>
                      <Form.Control
                        plaintext
                        readOnly
                        defaultValue={": " + this.props.data.no_segel_bawah}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              <Col className="col-table">
                <Form.Group>
                  <Form.Label className="label-pengiriman">
                    Keterangan
                  </Form.Label>
                  <Form.Control
                    plaintext
                    readOnly
                    defaultValue={this.props.data.catatan}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group>
              <Form.Label className="label-pengiriman">
                Biaya Perjalanan
              </Form.Label>
              <Form.Control
                plaintext
                readOnly
                defaultValue={
                  "Rp. " +
                  (this.props.data.biaya_penyebrangan !== null
                    ? formatNumber(this.props.data.biaya_penyebrangan)
                    : "-")
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="label-pengiriman">
                Catatan Tambahan{" "}
                {this.props.location.state.level === 1 &&
                  this.props.data.status === 1 &&
                  !this.state.success && <em> (Opsional)</em>}
              </Form.Label>
              <Form.Control
                readOnly={
                  this.state.auth.level !== "1" || this.props.data.status === 2
                }
                type="text"
                onChange={this.handleChange}
                placeHolder={this.keterangan}
                as="textArea"
                name="keterangan"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="label-pengiriman">
                Pemakaian (Liter)
              </Form.Label>
              <Form.Control
                readOnly={
                  this.state.auth.level !== "1" || this.props.data.status === 2
                }
                type="text"
                onChange={this.handleChange}
                className={this.state.error ? "invalid" : ""}
                value={this.pemakaian}
                name="pemakaian"
                isInvalid={this.state.error}
              />
              <Form.Control.Feedback type="invalid">
                {this.state.errorMessage}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Row>
        <div
          className="form-footer-container"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            width: "800px"
          }}
        >
          <div>
            <Button variant="primary" onClick={this.previewPrint}>
              Preview Nota
            </Button>
          </div>
          {this.state.auth.level === "1" && this.props.data.status === 1 && (
            <Form.Group>
              <div>
                <Button onClick={this.submit}>Pengiriman Selesai</Button>
              </div>
            </Form.Group>
          )}
          {/* {this.props.data.status === 2 && (
            <div>
              <Button variant={"primary"} onClick={this.printWhenDone}>
                Print
              </Button>
            </div>
          )} */}
          <div>
            {/* <Button variant={"secondary"} onClick={this.print}>
              Print Nota
            </Button> */}
            <Dropdown>
              <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                Prints
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {this.props.data.status === 2 && (
                  <Dropdown.Item onClick={this.printWhenDone}>
                    Print Detail Pengiriman
                  </Dropdown.Item>
                )}
                <Dropdown.Item onClick={() => this.printNota(false)}>
                  Print Nota
                </Dropdown.Item>
                <Dropdown.Item onClick={this.printNotaBlank}>
                  Print Nota Counter
                </Dropdown.Item>
                <Dropdown.Item onClick={this.printBerita}>
                  Print Berita Acara Penerimaan BBM
                </Dropdown.Item>
                <Dropdown.Item onClick={this.printLoadingOrder}>
                  Print Loading Order
                </Dropdown.Item>
                <Dropdown.Item onClick={this.printDeliveryOrder}>
                  Print Delivery Order
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div>
            <Button
              variant={"secondary"}
              onClick={() => {
                this.props.history.push({
                  pathname: "/FormNotaEdit",
                  state: {
                    ...this.props.data,
                    sameStatus: true
                  }
                });
              }}
            >
              Edit
            </Button>
          </div>
        </div>
        <div id="myMm" style={{ height: "1mm" }} />
      </div>
    );
  }
}

FormPengirimanCom.contextType = AuthContext;
export default FormPengirimanCom;
