import React, { Component, useState, useEffect } from "react";
import { useForm, ErrorMessage } from "react-hook-form";
import {
  Button,
  Form,
  Image,
  Col,
  Spinner,
  Row,
  InputGroup,
} from "react-bootstrap";
import "../styles/Colour_Style.css";
import "../styles/Form_Style.css";
import {
  handleTextInput,
  handleNumberInput,
  condensingWhitespace,
} from "../logics/FormInput_Logic";
import { useAuth } from "../context/Auth_Context";
import { editNota, submitNota } from "../logics/SubmitForm_Logic";
import { getLocalDate } from "../logics/CreateDateTime_Logic";
import {
  fetchSupir,
  fetchTitikPengambilan,
  fetchKendaraan,
  fetchPenerima,
  fetchProduk,
  fetchPerusahaanPengiriman,
} from "../logics/FetchData_Logic";

// var fetchState = {
//   supir: false,
//   kendaraan: false,
//   perusahaan_pengirim: false,
//   portal: false,
//   customer: false,
//   produk: false,
// };

var nama_supir = "";
var no_kendaraan = "";
var no_hp = "";
var nama_perusahaan_tujuan = "";
var alamat_perusahaan_tujuan = "";

function FormNotaFunCom(props) {
  var isDraft = 0;
  const {
    register,
    handleSubmit,
    errors,
    setError,
    clearError,
    triggerValidation,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    validateCriteriaMode: "all",
    defaultValues: {
      tgl_pengiriman: props.data.detailNota
        ? props.data.detailNota.tanggal_pengirim.substring(0, 10)
        : "",
      no_po: props.data.detailNota ? props.data.detailNota.no_po : "",
      perusahaan_pengirim: props.data.detailNota
        ? props.data.detailNota.asal_pengiriman._id
        : "",
      portal: props.data.detailNota
        ? props.data.detailNota.titik_pengambilan._id
        : "",
      customer: props.data.detailNota
        ? props.data.detailNota.tujuan_pengiriman._id
        : "",
      supir: props.data.detailNota ? props.data.detailNota.supirId._id : "",
      supir_lain:
        props.data.detailNota &&
        props.data.detailNota.supirId._id === props.data.supir.otherId
          ? props.data.detailNota.nama_supir
          : "",
      no_hp:
        props.data.detailNota &&
        props.data.detailNota.supirId._id === props.data.supir.otherId
          ? props.data.detailNota.no_hp_supir
          : "",
      no_kendaraan: props.data.detailNota
        ? props.data.detailNota.kendaraanId._id
        : "",
      no_kendaraan_lain: props.data.detailNota
        ? props.data.detailNota.no_polisi
        : "",
      //   kendaraan: props.data.detailNota
      //     ? props.data.detailNota.nama_kendaraan
      //     : "",
      kendaraan_lain: props.data.detailNota
        ? props.data.detailNota.nama_kendaraan
        : "",
      //   perusahaan_pemilik: props.data.detailNota
      //     ? props.data.detailNota.perusahaan_pemiliki_kendaraan
      //     : "",
      perusahaan_pemilik_lain: props.data.detailNota
        ? props.data.detailNota.perusahaan_pemiliki_kendaraan
        : "",
      premi: props.data.detailNota ? props.data.detailNota.biaya_premi : "",
      produk: props.data.detailNota
        ? props.data.detailNota.jenis_produk._id
        : "",
      kuantitas: props.data.detailNota ? props.data.detailNota.kuantitas : "",
      harga: props.data.detailNota ? props.data.detailNota.harga_produk : "",
      no_segel_atas: props.data.detailNota
        ? props.data.detailNota.no_segel_atas
        : "",
      no_segel_bawah: props.data.detailNota
        ? props.data.detailNota.no_segel_bawah
        : "",
      biaya_portal: props.data.detailNota
        ? props.data.detailNota.biaya_penyebrangan
        : "",
      catatan: props.data.detailNota ? props.data.detailNota.catatan : "",
      nama_perusahaan_tujuan: props.data.detailNota
        ? props.data.detailNota.nama_perusahaan_tujuan
        : "",
      alamat_perusahaan_tujuan: props.data.detailNota
        ? props.data.detailNota.alamat_perusahaan_tujuan
        : "",
      site: props.data.detailNota
        ? props.data.detailNota.site
        : "",
    },
  });

  const [state, setState] = useState({
    image: "assets/image.png",
    loading: false,
    isDraft: false,
    isOrder: false,
    preview: false,
    supir_copy:
      props.data.detailNota && props.data.detailNota.supirId._id
        ? props.data.detailNota.nama_supir
        : "",
    supir:
      props.data.detailNota &&
      props.data.detailNota.supirId._id &&
      props.data.detailNota.supirId._id === props.data.supir.otherId, //show supir other option
    kendaraan: props.data.detailNota
      ? props.data.detailNota.nama_kendaraan
      : "",
    chosedKendaraan:
      props.data.detailNota &&
      props.data.detailNota.kendaraanId._id &&
      props.data.detailNota.kendaraanId._id !== props.data.kendaraan.otherId, //kendaraan detail
    no_kendaraan:
      props.data.detailNota &&
      props.data.detailNota.kendaraanId._id &&
      props.data.detailNota.kendaraanId._id === props.data.kendaraan.otherId, //kendaraan lain
    perusahaan_pemilik: props.data.detailNota
      ? props.data.detailNota.perusahaan_pemiliki_kendaraan
      : "",
    pj_perusahaan_pengirim:
      props.data.detailNota && props.data.detailNota.asal_pengiriman._id
        ? props.data.detailNota.pj_pengirim
        : "",
    pj_customer: props.data.detailNota ? props.data.detailNota.pj_penerima : "bapak/ibu",
    disableKendaraan:
      props.data.detailNota && props.data.detailNota.supirId._id ? false : true,
    customer:
      props.data.detailNota &&
      props.data.detailNota.tujuan_pengiriman._id &&
      props.data.detailNota.tujuan_pengiriman._id ===
        props.data.perusahaan_penerima.otherId,
    sameStatus: props.location.state ? (props.location.state.sameStatus || false) : false,
    supirData: props.data.supir, //supir dropdown data
    transportirId: props.data.detailNota
      ? props.data.detailNota.asal_pengiriman._id
      : "",
  });
  const { auth, setToken } = useAuth();

  const getSpecificKendaraanData = (id) => {
    //jenis & nama perusahaan pengirim only
    for (let i = 0; i < props.data.kendaraan.kendaraan.length; i++) {
      if (props.data.kendaraan.kendaraan[i]._id === id) {
        return [
          props.data.kendaraan.kendaraan[i].jenis,
          props.data.kendaraan.kendaraan[i].idPengirim.nama,
          props.data.kendaraan.kendaraan[i].no_polisi,
        ];
      }
    }
    return null; //shouldn't be null
  };

  const handleSelect = (event) => {
    let editSupir = false;
    let disableKendaraan = null;
    let selected = "";
    let chosedKendaraan = null;
    let data_index = -1;
    let curr_transportirId = state.transportirId;
    let child = Array.from(event.target.childNodes);
    let pj_asal = state.pj_perusahaan_pengirim;
    let pj_customer = state.pj_customer;
    let chosedKendaraanData = []; //only used if event from supir
    
    child.forEach(function (item) {
      if (item.selected) {
        selected = item.innerHTML;
        data_index = item.getAttribute('idx')
      }
    });
    console.log(data_index)
    if (event.target.name === "supir") {
      editSupir = true;
      disableKendaraan = data_index >= 0 ? false : true; //make input no kendaraan available if supir is selected
      nama_supir = selected;
      if (selected !== "LAINNYA" && data_index >= 0) {
        // pengambilan data dari props ga boleh pake select_index, karena 
        // nanti urutan supirnya berbuah sesuai pilihan trnasportir (jadi ga tepat)
        // --> select index relatif ke urutan opsi yang di render, bukan sesuai index props data
        no_hp = props.data.supir.supir[data_index].no_hp;
        let idKendaraan = props.data.supir.supir[data_index].idKendaraan._id;
        console.log(idKendaraan)
        chosedKendaraanData = getSpecificKendaraanData(idKendaraan);
        if (chosedKendaraanData === null) {
          //either data kendaraannya dihapus jadi pasangan supir-kendaraan ngga ada kendaraanya,
          //atau ada kesalahan pas ngebuat data supir (data kendaraan yg dihapus masih bisa dipilih)
          chosedKendaraan = null;
        } else {
          chosedKendaraan = true;
          setValue(
            "no_kendaraan",
            props.data.supir.supir[data_index].idKendaraan._id
          );
          console.log(idKendaraan);
          no_kendaraan = chosedKendaraanData[2];
        }
      }
    } else if (event.target.name === "no_kendaraan") {
      no_kendaraan = selected;
      chosedKendaraan = selected !== "LAINNYA" && data_index >= 0;
    } else {
      if (data_index >= 0) {
        if (event.target.name === "perusahaan_pengirim"){
          pj_asal =
            props.data.perusahaan_pengirim[data_index].penanggung_jawab;
          curr_transportirId = event.target.value
        }
        if (event.target.name === "customer") {
          if (selected !== "LAINNYA") {
            nama_perusahaan_tujuan =
              props.data.perusahaan_penerima.customers[data_index]
                .nama_perusahaan;
            alamat_perusahaan_tujuan =
              props.data.perusahaan_penerima.customers[data_index].alamat;
            pj_customer =
              props.data.perusahaan_penerima.customers[data_index].penerima;
          } else {
            pj_customer = "bapak/ibu";
          }
        }
      } else if (event.target.name === "perusahaan_pengirim") curr_transportirId = "";
    }
    setState({
      ...state,
      [event.target.name]: selected === "LAINNYA", //display other option or not
      supir_copy: editSupir
        ? selected === "LAINNYA"
          ? getValues("supir_lain")
          : selected === "Pilih Supir"
          ? ""
          : selected
        : state.supir_copy,
      chosedKendaraan:
        chosedKendaraan === null ? state.chosedKendaraan : chosedKendaraan,
      kendaraan: chosedKendaraan
        ? event.target.name === "no_kendaraan"
          ? props.data.kendaraan.kendaraan[data_index].jenis
          : chosedKendaraanData[0] //somefunction,
        : state.kendaraan,
      perusahaan_pemilik: chosedKendaraan
        ? event.target.name === "no_kendaraan"
          ? props.data.kendaraan.kendaraan[data_index].idPengirim.nama
          : chosedKendaraanData[1] //somefunction
        : state.perusahaan_pemilik,
      pj_perusahaan_pengirim: pj_asal,
      pj_customer: pj_customer,
      disableKendaraan:
        disableKendaraan === null ? state.disableKendaraan : disableKendaraan,
      no_kendaraan:
        event.target.name === "no_kendaraan"
          ? data_index >= 0 && !chosedKendaraan // pilihan LAINNYA, maka true
          : event.target.name === "supir"
          ? chosedKendaraan //supir data, bukan "" atau lainnya
            ? false //jangan tampilin
            : state.no_kendaraan //ikut state sebelunya
          : state.no_kendaraan,
      transportirId: curr_transportirId,
    });
  };

  const handleNext = async (event) => {
    if (event.target.name === "next") {
      await triggerValidation();
      let value = condensingWhitespace(getValues());
      const keys = Object.keys(value);
      for (const key of keys) {
        setValue(key, value[key]);
      }
      window.scrollTo(0, 0);
      setState({
        ...state,
        preview:
          Object.keys(errors).length === 0 && errors.constructor === Object
            ? true
            : false,
      });
    } else {
      setState({
        ...state,
        preview: false,
      });
    }
  };

  const minVal = (value) => {
    clearError("minimal");
    if (parseInt(value) < 1) {
      setError("minimal", "min", "Input tidak boleh dibawah 1");
    }
  };
  const handleChange = (event) => {
    let val = "";
    let number_inp = [
      "no_hp",
      "biaya_portal",
      "premi",
      "kuantitas",
      "harga",
      "no_segel_atas",
      "no_segel_bawah",
    ];
    let supirChanged = event.target.name === "supir_lain";
    if (number_inp.includes(event.target.name)) {
      val = handleNumberInput(event);
      if (
        !["no_hp", "no_segel_atas", "no_segel_bawah"].includes(
          event.target.name
        )
      )
        val = val ? parseInt(val, 10) + "" : "";
    } else {
      val = handleTextInput(
        event.target.name === "no_kendaraan_lain"
          ? "no_polisi"
          : event.target.name === "perusahaan_pemilik_lain" ||
            event.target.name === "nama_perusahaan_tujuan" ||
            event.target.name === "alamat_perusahaan_tujuan"
          ? "perusahaan"
          : event.target.name,
        event
      );
    }
    setValue(event.target.name, val);
    setState({
      ...state,
      supir_copy: supirChanged ? getValues("supir_lain") : state.supir_copy,
    });
  };

  const onSubmit = async (value) => {
    value.status = isDraft;
    console.log(value.status)
    value.nama_supir =
      nama_supir ||
      (props.data.detailNota ? props.data.detailNota.nama_supir : "");
    value.no_polisi =
      no_kendaraan ||
      (props.data.detailNota ? props.data.detailNota.no_polisi : "");
    value.no_hp_ori =
      no_hp || (props.data.detailNota ? props.data.detailNota.no_hp_supir : "");
    value.customer_pic =
      state.pj_customer ||
      (props.data.detailNota ? props.data.detailNota.pj_penerima : "bapak/ibu");
    value.nama_perusahaan_tujuan =
      value.nama_perusahaan_tujuan ||
      nama_perusahaan_tujuan ||
      (props.data.detailNota
        ? props.data.detailNota.nama_perusahaan_tujuan
        : "");
    value.alamat_perusahaan_tujuan =
      value.alamat_perusahaan_tujuan ||
      alamat_perusahaan_tujuan ||
      (props.data.detailNota
        ? props.data.detailNota.alamat_perusahaan_tujuan
        : "");
    console.log(value);
    let success = false;
    let status = [];
    let message = [];
    value = condensingWhitespace(value);
    let response = await submitNota(
      auth.token,
      value,
      props.data.detailNota ? true : false,
      props.data.detailNota ? props.data.detailNota._id : null
    );
    if (response[0] && response[0].status === 200) {
      response[0].token && setToken(response[0].token);
      success = true;
    } else if (response[0] && response[0].status === 402) {
      let path = "/";
      this.props.history.push({
        pathname: path,
        state:
          "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi.",
      });
    } else {
      if (response[1]) {
        response[1].error.map((item) => {
          status = status.concat(1);
          message = message.concat(
            "Status: " +
              response[1].status +
              " - " +
              response[1].message +
              ". " +
              item.msg
          );
        });
      } else {
        status = status.concat(1);
        message = message.concat(
          "Status: " +
            response[0].status +
            " - " +
            response[0].message +
            ". " +
            response[0].error[0].msg
        );
      }
    }
    setState({
      ...state,
      loading: false,
    });
    if (success) {
      if (props.data.detailNota)
        props.showModal({ changed: false, to: value.no_nota }, true);
      else props.showModal(response[0].data[0][1], false);
    } else {
      let idx = 0;
      status.map(function (item) {
        props.generateToast(item, message[idx]);
        idx = idx + 1;
      });
    }
  };

  const handleBtnClick = (event) => {
    isDraft = event.target.name === "draft" ? 0 : (event.target.name === "order" ? 1 : props.data.detailNota.status);
    setState({
      ...state,
      isDraft: event.target.name === "draft",
      isOrder: event.target.name === "order",
      loading: true,
    });
    handleSubmit(onSubmit)();
  };

  return (
    <div className="container">
      {props.data.detailNota && (
        <h4>
          Sedang Mengedit Informasi Nota No. {props.data.detailNota.no_nota}
        </h4>
      )}
      {state.preview && <h3>KONFIRMASI DATA INPUT NOTA</h3>}
      <Form className="form">
        {/* </div>onSubmit={handleSubmit(onSubmit)}> */}
        <Form.Group>
          <Form.Label>Tanggal Pengiriman :</Form.Label>
          <Form.Control
            className={errors.tgl_pengiriman && "invalid"}
            name="tgl_pengiriman"
            ref={register({ required: "Input tidak boleh kosong" })}
            type="date"
            min={"2010-01-01"}
            disabled={state.preview}
          />
          <ErrorMessage errors={errors} name="tgl_pengiriman">
            {({ message }) => <p className="error_info">{message}</p>}
          </ErrorMessage>
        </Form.Group>
        <Row>
          <Form.Group as={Col}>
            <Form.Label>No PO :</Form.Label>
            <Form.Control
              className={errors.no_po && "invalid"}
              name="no_po"
              ref={register({ required: "Input tidak boleh kosong" })}
              type="text"
              onChange={handleChange}
              disabled={state.preview}
            />
            <ErrorMessage errors={errors} name="no_po">
              {({ message }) => <p className="error_info">{message}</p>}
            </ErrorMessage>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>No Nota Pengiriman :</Form.Label>
            <Form.Control
              className={errors.no_nota && "invalid"}
              type="text"
              readOnly
              name="no_nota"
              ref={register({ required: true })}
              defaultValue={
                props.data.detailNota
                  ? props.data.detailNota.no_nota
                  : props.data.no_nota[0].no_nota || "000000"
              }
            />
          </Form.Group>
        </Row>
        <Form.Group>
          <Form.Label>
            Transportir :{" "}
            {/* {pengirimLoading && (
              <Spinner variant="primary" animation="border" />
            )} */}
          </Form.Label>
          <Form.Control
            className={errors.perusahaan_pengirim && "invalid"}
            as="select"
            name="perusahaan_pengirim"
            onChange={handleSelect}
            //onClick={handleClick}
            ref={register({ required: "Input tidak boleh kosong" })}
            disabled={state.preview}
          >
            <option value="">Pilih Transportir</option>
            {props.data.perusahaan_pengirim.map((row,index) => (
              <option name="supir" key={row._id} value={row._id} idx={index}>
                {row.nama}
              </option>
            ))}
          </Form.Control>
          <ErrorMessage errors={errors} name="perusahaan_pengirim">
            {({ message }) => <p className="error_info">{message}</p>}
          </ErrorMessage>
        </Form.Group>
        <Form.Group>
          <Form.Label>
            Titik Pengambilan Barang :{" "}
            {/* {portalLoading && <Spinner variant="primary" animation="border" />} */}
          </Form.Label>
          <Form.Control
            className={errors.portal && "invalid"}
            as="select"
            name="portal"
            //onClick={handleClick}
            ref={register({ required: "Input tidak boleh kosong" })}
            disabled={state.preview}
          >
            <option value="">Pilih Titik Pengambilan</option>
            {props.data.portal.map((row,index) => (
              <option name="supir" key={row._id} value={row._id} idx={index}>
                {row.nama}
              </option>
            ))}
          </Form.Control>
          <ErrorMessage errors={errors} name="portal">
            {({ message }) => <p className="error_info">{message}</p>}
          </ErrorMessage>
        </Form.Group>
        <Form.Group>
          <Form.Label>
            Customer :{" "}
            {/* {customerLoading && (
              <Spinner variant="primary" animation="border" />
            )} */}
          </Form.Label>
          <Form.Control
            className={errors.customer && "invalid"}
            as="select"
            name="customer"
            onChange={handleSelect}
            //onClick={handleClick}
            ref={register({ required: "Input tidak boleh kosong" })}
            disabled={state.preview}
          >
            <option value="">Pilih Customer</option>
            {props.data.perusahaan_penerima.customers.map((row,index) => (
              <option name="supir" key={row._id} value={row._id} idx={index}>
                {row.nama_perusahaan}
              </option>
            ))}
            <option value={props.data.perusahaan_penerima.otherId}>
              LAINNYA
            </option>
          </Form.Control>
          <ErrorMessage errors={errors} name="customer">
            {({ message }) => <p className="error_info">{message}</p>}
          </ErrorMessage>
        </Form.Group>
        {state.customer && (
          <>
            <Form.Group>
              <Form.Label>Nama Perusahaan Customer Lain :</Form.Label>
              <Form.Control
                className={errors.nama_perusahaan_tujuan && "invalid"}
                type="text"
                name="nama_perusahaan_tujuan"
                onChange={handleChange}
                ref={
                  state.customer
                    ? register({ required: "Input tidak boleh kosong" })
                    : register()
                }
                disabled={state.preview}
              />
              <ErrorMessage errors={errors} name="nama_perusahaan_tujuan">
                {({ message }) => <p className="error_info">{message}</p>}
              </ErrorMessage>
            </Form.Group>
            <Form.Group>
              <Form.Label>Alamat Perusahaan customer Lain :</Form.Label>
              <Form.Control
                className={errors.alamat_perusahaan_tujuan && "invalid"}
                type="text"
                name="alamat_perusahaan_tujuan"
                onChange={handleChange}
                ref={
                  state.customer
                    ? register({ required: "Input tidak boleh kosong" })
                    : register()
                }
                disabled={state.preview}
              />
              <ErrorMessage errors={errors} name="alamat_perusahaan_tujuan">
                {({ message }) => <p className="error_info">{message}</p>}
              </ErrorMessage>
            </Form.Group>
          </>
        )}
        <Form.Group>
          <Form.Label>Site : </Form.Label>
          <Form.Control
            className={errors.site && "invalid"}
            type="text"
            name="site"
            onChange={handleChange}
            ref={register({ required: "Input tidak boleh kosong" })}
            disabled={state.preview}
          />
          <ErrorMessage errors={errors} name="site">
            {({ message }) => <p className="error_info">{message}</p>}
          </ErrorMessage>
        </Form.Group>
        <Form.Group>
          <Form.Label>
            Nama Supir :{" "}
            {/* {supirLoading && <Spinner variant="primary" animation="border" />} */}
          </Form.Label>
          <Form.Control
            className={errors.supir && "invalid"}
            as="select"
            name="supir"
            onChange={handleSelect}
            //onClick={handleClick}
            ref={register({ required: "Input tidak boleh kosong" })}
            disabled={state.preview}
          >
            <option value="">Pilih Supir</option>
            {state.supirData.supir.map((row,index) => {
              if (state.transportirId === "" || row.idPengirim === state.transportirId){
                return (
                  <option name="supir" key={row._id} value={row._id} idx={index}>
                    {row.nama}
                  </option>
                );
              }
            })}
            <option name="supir" value={state.supirData.otherId}>
              LAINNYA
            </option>
          </Form.Control>
          <ErrorMessage errors={errors} name="supir">
            {({ message }) => <p className="error_info">{message}</p>}
          </ErrorMessage>
        </Form.Group>
        <Row>
          {state.supir && (
            <Form.Group as={Col} sm={6}>
              <Form.Label>Nama Supir Lain :</Form.Label>
              <Form.Control
                className={errors.supir_lain && "invalid"}
                type="text"
                name="supir_lain"
                onChange={handleChange}
                ref={
                  state.supir
                    ? register({ required: "Input tidak boleh kosong" })
                    : register()
                }
                disabled={state.preview}
              />
              <ErrorMessage errors={errors} name="supir_lain">
                {({ message }) => <p className="error_info">{message}</p>}
              </ErrorMessage>
            </Form.Group>
          )}
          {state.supir && (
            <Form.Group as={Col} sm={6}>
              <Form.Label>No HP :</Form.Label>
              <Form.Control
                className={errors.no_hp && "invalid"}
                type="tel"
                name="no_hp"
                onChange={handleChange}
                disabled={state.preview}
                ref={
                  state.supir
                    ? register({
                        required: "Input tidak boleh kosong",
                        pattern: {
                          value: /^[+-]?\d+$/,
                          message:
                            "Format salah, contoh yang benar: +6221222333 / 021222333",
                        },
                        maxLength: {
                          value: 16,
                          message: "Nomor terlalu panjang, maksimal 16 digit",
                        },
                        minLength: {
                          value: 8,
                          message: "Nomor terlalu pendek, minimal 8 digit",
                        },
                      })
                    : register()
                }
              />
              <ErrorMessage errors={errors} name="no_hp">
                {({ message }) => <p className="error_info">{message}</p>}
              </ErrorMessage>
            </Form.Group>
          )}
        </Row>
        <Form.Group>
          <Form.Label>
            No Polisi Armada :{" "}
            {/* {kendaraanLoading && (
              <Spinner variant="primary" animation="border" />
            )} */}
          </Form.Label>
          <Form.Control
            className={errors.no_kendaraan && "invalid"}
            as="select"
            name="no_kendaraan"
            disabled={state.disableKendaraan || state.preview}
            onChange={handleSelect}
            //onClick={handleClick}
            ref={register({ required: "Input tidak boleh kosong" })}
          >
            <option value="">Pilih No. Armada</option>
            {props.data.kendaraan.kendaraan.map((row,index) => {
              return (
                <option name="kendaraan" key={row._id} value={row._id} idx={index}>
                  {row.no_polisi}
                </option>
              );
            })}
            <option name="kendaraan" value={props.data.kendaraan.otherId}>
              LAINNYA
            </option>
          </Form.Control>
          <ErrorMessage errors={errors} name="no_kendaraan">
            {({ message }) => <p className="error_info">{message}</p>}
          </ErrorMessage>
        </Form.Group>
        {/* </Form>{state.kendaraan && <Form.Group> */}
        {state.no_kendaraan && (
          <Form.Group>
            <Form.Label>No Polisi Armada Lain :</Form.Label>
            <Form.Control
              className={errors.no_kendaraan_lain && "invalid"}
              type="text"
              name="no_kendaraan_lain"
              onChange={handleChange}
              disabled={state.disableKendaraan || state.preview}
              ref={
                state.kendaraan
                  ? register({ required: "Input tidak boleh kosong" })
                  : register()
              }
            />
            <ErrorMessage errors={errors} name="no_kendaraan_lain">
              {({ message }) => <p className="error_info">{message}</p>}
            </ErrorMessage>
          </Form.Group>
        )}
        {state.chosedKendaraan && (
          <Form.Group>
            <Form.Label>Jenis Armada :</Form.Label>
            <Form.Control
              className={errors.kendaraan && "invalid"}
              name="kendaraan"
              type="text"
              value={state.kendaraan}
              ref={register({ required: "Input tidak boleh kosong" })}
              readOnly
            />
            <ErrorMessage errors={errors} name="kendaraan">
              {({ message }) => <p className="error_info">{message}</p>}
            </ErrorMessage>
          </Form.Group>
        )}
        {state.no_kendaraan && (
          <Form.Group>
            <Form.Label>Nama Armada Lain :</Form.Label>
            <Form.Control
              className={errors.kendaraan_lain && "invalid"}
              type="text"
              name="kendaraan_lain"
              onChange={handleChange}
              disabled={state.disableKendaraan || state.preview}
              ref={
                state.kendaraan
                  ? register({ required: "Input tidak boleh kosong" })
                  : register()
              }
            />
            <ErrorMessage errors={errors} name="kendaraan_lain">
              {({ message }) => <p className="error_info">{message}</p>}
            </ErrorMessage>
          </Form.Group>
        )}
        {state.chosedKendaraan && (
          <Form.Group>
            <Form.Label>Nama Perusahaan Pemiliki Armada :</Form.Label>
            <Form.Control
              className={errors.perusahaan_pemilik && "invalid"}
              type="text"
              name="perusahaan_pemilik"
              value={state.perusahaan_pemilik}
              disabled={state.preview}
              readOnly
              ref={register({ required: "Input tidak boleh kosong" })}
            />
            <ErrorMessage errors={errors} name="perusahaan_pemilik">
              {({ message }) => <p className="error_info">{message}</p>}
            </ErrorMessage>
          </Form.Group>
        )}
        {/* </Form>{state.kendaraan && <Form.Group> */}
        {state.no_kendaraan && (
          <Form.Group>
            <Form.Label>Nama Perusahaan Pemilik Armada Lain :</Form.Label>
            <Form.Control
              className={errors.perusahaan_pemilik_lain && "invalid"}
              type="text"
              name="perusahaan_pemilik_lain"
              onChange={handleChange}
              disabled={state.disableKendaraan || state.preview}
              ref={
                state.kendaraan
                  ? register({ required: "Input tidak boleh kosong" })
                  : register()
              }
            />
            <ErrorMessage errors={errors} name="perusahaan_pemilik_lain">
              {({ message }) => <p className="error_info">{message}</p>}
            </ErrorMessage>
          </Form.Group>
        )}
        <Form.Group>
          <Form.Label>Biaya Premi :</Form.Label>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text>Rp.</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              className={errors.premi && "invalid"}
              type="text"
              name="premi"
              disabled={state.preview}
              onChange={handleChange}
              ref={register({ required: "Input tidak boleh kosong" })}
            />
            <ErrorMessage errors={errors} name="premi">
              {({ message }) => <p className="error_info">{message}</p>}
            </ErrorMessage>
          </InputGroup>
        </Form.Group>
        <Form.Group>
          <Form.Label>
            Jenis Produk Yang Dibawa :{" "}
            {/* {produkLoading && <Spinner variant="primary" animation="border" />} */}
          </Form.Label>
          <Form.Control
            className={errors.produk && "invalid"}
            ref={register({ required: "Input tidak boleh kosong" })}
            name="produk"
            disabled={state.preview} //|| produkLoading}
            as="select"
            //onClick={handleClick}
          >
            <option value="">Pilih Produk</option>
            {props.data.produk.map((row,index) => (
              <option name="supir" value={row._id} idx={index}>
                {row.jenis}
              </option>
            ))}
          </Form.Control>
          <ErrorMessage errors={errors} name="produk">
            {({ message }) => <p className="error_info">{message}</p>}
          </ErrorMessage>
        </Form.Group>
        <Row>
          <Form.Group as={Col}>
            <Form.Label>Quantity Produk Yang Dibawa :</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                className={(errors.kuantitas || errors.minimal) && "invalid"}
                type="text"
                placeholder="Satuan Liter"
                name="kuantitas"
                onChange={handleChange}
                disabled={state.preview}
                ref={register({
                  required: "Input tidak boleh kosong",
                  validate: (value) => minVal(value),
                })}
              />
              <InputGroup.Append>
                <InputGroup.Text>Liter</InputGroup.Text>
              </InputGroup.Append>
              <ErrorMessage errors={errors} name="kuantitas">
                {({ message }) => <p className="error_info">{message}</p>}
              </ErrorMessage>
              <ErrorMessage errors={errors} name="minimal">
                {({ message }) => <p className="error_info">{message}</p>}
              </ErrorMessage>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Harga Produk :</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>Rp.</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                className={errors.harga && "invalid"}
                name="harga"
                type="text"
                ref={register({ required: "Input tidak boleh kosong" })}
                onChange={handleChange}
                disabled={state.preview}
                placeholder="Satuan Rupiah"
              />
              <ErrorMessage errors={errors} name="harga">
                {({ message }) => <p className="error_info">{message}</p>}
              </ErrorMessage>
            </InputGroup>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <Form.Label>No Segel Atas :</Form.Label>
            <Form.Control
              type="text"
              className={errors.no_segel_atas && "invalid"}
              name="no_segel_atas"
              ref={register({ required: "Input tidak boleh kosong" })}
              disabled={state.preview}
              onChange={handleChange}
            />
            <ErrorMessage errors={errors} name="no_segel_atas">
              {({ message }) => <p className="error_info">{message}</p>}
            </ErrorMessage>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>No Segel Bawah :</Form.Label>
            <Form.Control
              type="text"
              className={errors.no_segel_bawah && "invalid"}
              name="no_segel_bawah"
              ref={register({ required: "Input tidak boleh kosong" })}
              disabled={state.preview}
              onChange={handleChange}
            />
            <ErrorMessage errors={errors} name="no_segel_bawah">
              {({ message }) => <p className="error_info">{message}</p>}
            </ErrorMessage>
          </Form.Group>
        </Row>
        <Form.Group>
          <Form.Label>
            Ongkos Angkut : <em> (Opsional)</em>
          </Form.Label>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text>Rp.</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="text"
              placeholder="Satuan Rupiah"
              className={errors.biaya_portal && "invalid"}
              name="biaya_portal"
              ref={register()} //opsional
              disabled={state.preview}
              onChange={handleChange}
            />
            <ErrorMessage errors={errors} name="biaya_portal">
              {({ message }) => <p className="error_info">{message}</p>}
            </ErrorMessage>
          </InputGroup>
        </Form.Group>
        <Form.Group>
          <Form.Label>
            Keterangan : <em> (Opsional)</em>
          </Form.Label>
          <Form.Control
            type="text"
            className={errors.catatan && "invalid"}
            name="catatan"
            disabled={state.preview}
            ref={register()}
          />
          <ErrorMessage errors={errors} name="catatan">
            {({ message }) => <p className="error_info">{message}</p>}
          </ErrorMessage>
        </Form.Group>
        <Form.Group>
          <Form.Label>Nama Penanggung Jawab Pengirim :</Form.Label>
          <Form.Control
            type="text"
            className={errors.penanggung_jawab && "invalid"}
            name="penanggung_jawab"
            ref={register({
              required:
                "Input tidak boleh kosong. Silahkan pilih field asal pengiriman",
            })}
            disabled={state.preview}
            readOnly
            value={state.pj_perusahaan_pengirim}
          />
          <ErrorMessage errors={errors} name="penanggung_jawab">
            {({ message }) => <p className="error_info">{message}</p>}
          </ErrorMessage>
        </Form.Group>
        <Form.Group>
          <Form.Label>Nama Supir :</Form.Label>
          <Form.Control
            type="text"
            className={errors.supir && "invalid"}
            name="supir_display"
            readOnly
            ref={register({
              required: "Input tidak boleh kosong. Silahkan pilih field supir",
            })}
            value={state.supir_copy}
          />
          <ErrorMessage errors={errors} name="supir_display">
            {({ message }) => <p className="error_info">{message}</p>}
          </ErrorMessage>
        </Form.Group>
        <div className="btn-container">
          {!state.preview && (
            <Button name="next" variant="primary" onClick={handleNext}>
              Preview
            </Button>
          )}
          {state.preview && (
            <>
              <Button
              name="back"
              clasName="btn-secondary"
              variant="primary"
              onClick={handleNext}
              disabled={state.loading}
            >
              Back
            </Button>
            </> )}
          { state.preview && ( state.sameStatus ?
            <Button
              variant="primary"
              onClick={handleBtnClick}
              name="edit"
              disabled={state.loading}
            >
              {state.loading ? (
                  <Spinner animation="border" variant="secondary" /> )
                  :
              "Save"}
            </Button>
            :
            <>
              <Button
                variant="secondary"
                onClick={handleBtnClick}
                name="draft"
                disabled={state.loading}
              >
                {state.loading && state.isDraft ? (
                  <Spinner animation="border" variant="secondary" />
                ) : (
                  "Save as Draft"
                )}
              </Button>
              <Button
                variant="primary"
                onClick={handleBtnClick}
                name="order"
                disabled={state.loading}
              >
                {state.loading && state.isOrder ? (
                  <Spinner animation="border" variant="secondary" />
                ) : (
                  "Confirm Order"
                )}
              </Button>
            </>
          )}
        </div>
      </Form>
    </div>
  );
}

class FormNotaCom extends Component {
  constructor(props) {
    super(props);
    this.resetVar();
  }

  resetVar = () => {
    // fetchState = {
    //   supir: false,
    //   kendaraan: false,
    //   perusahaan_pengirim: false,
    //   portal: false,
    //   customer: false,
    //   produk: false,
    // };
    nama_supir = "";
    no_kendaraan = "";
    no_hp = "";
    nama_perusahaan_tujuan = "";
    alamat_perusahaan_tujuan = "";
  };

  render() {
    return <FormNotaFunCom {...this.props} />;
  }
}

export default FormNotaCom;
