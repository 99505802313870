import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { History } from "react-router-dom";
import { NavLink } from "react-router-dom";
import {
  fetchPerusahaan,
  fetchPerusahaanPengiriman
} from "../logics/FetchData_Logic";
import { AuthContext } from "../context/Auth_Context";

const $ = require("jquery");
$.Datatable = require("datatables.net");

class TabelCom extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataTable: null,
      data: null
    };
  }

  edit = (data) => {
    let path =
      this.props.status === "TableNota"
        ? "/FormNotaEdit"
        : "/Form" + this.props.status.substring(5, this.props.status.length);
    this.props.history.push({
      pathname: path,
      state: data
    });
  };

  preview_nota = (data, extra_path) => {
    let path =
      "/FormPengiriman" + (extra_path !== "undefined" ? extra_path : "");
    switch (extra_path) {
      case "Perusahaan":
        data.level = 1;
        break;
      case "Kota":
        data.level = 2;
        break;
      default:
        data.level = 3;
        break;
    }
    this.props.history.push({
      pathname: path,
      state: data
    });
  };

  preview_premi = (id, nama, polisi) => {
    let path = "/TablePremi";
    this.props.history.push({
      pathname: path,
      state: {
        id: id,
        nama: nama,
        polisi: polisi
      }
    });
  };

  updatePremi = (data, row) => {
    this.props.updatePremi(data, row, this.changeStatusPremi);
  };

  printPremi = (event) => {
    event.preventDefault();
    this.props.printPremi();
  };

  delete = (data) => {
    this.props.delete(data);
  };

  preview = (data, type) => {
    this.props.preview && this.props.preview(data, type);
  };

  changeStatusPremi = (row_idx) => {
    var dt = $(".dataTable").DataTable();
    var row = dt.row(row_idx).data();
    row.status_Premi = true;
    dt.row(row_idx).data(row).draw();
  };

  componentDidMount() {
    var componentInstance = this;
    this.$el = $(this.el);
    this.props.status === "TablePremi" &&
      this.props.link(this.changeStatusPremi);
    let dataTable = this.$el.DataTable({
      ...this.props.data,
      drawCallback: function () {
        var api = this.api();
        var rowCount = api.rows({ page: "current" }).count();
        let td = "<td>&nbsp;</td>";
        for (let i = 0; i < api.columns()[0].length - 1; i++) {
          td = td + "<td></td>";
        }
        let rowType = rowCount % 2 === 1 ? "even" : "odd";
        for (
          var i = 0;
          i < api.page.len() - (rowCount === 0 ? 1 : rowCount);
          i++
        ) {
          $("#data_table tbody").append(
            $("<tr class='" + rowType + "' role='row'>" + td + "</tr>")
          );
          rowType = rowType === "odd" ? "even" : "odd";
        }
      }
    });
    //let emptyRowComp = dataTable.columns().dataSrc();
    $("#data_table tbody").on("click", "img.edit", function () {
      let obj = dataTable.row($(this).parents("tr")).data();
      componentInstance.edit(obj);
    });
    $("#data_table tbody").on("click", "Button.prev", function () {
      let obj = dataTable.row($(this).parents("tr")).data();
      let path = $(this)[0].name;
      componentInstance.preview_nota(obj, path);
    });
    $("#data_table tbody").on("click", "img.delete", function () {
      let obj = dataTable.row($(this).parents("tr")).data();
      componentInstance.delete(obj);
    });
    $("#data_table tbody").on("click", "img.nik", function () {
      let obj = dataTable.row($(this).parents("tr")).data();
      componentInstance.preview(obj.path_uri_ktp, "nik");
    });
    $("#data_table tbody").on("click", "img.sim", function () {
      let obj = dataTable.row($(this).parents("tr")).data();
      componentInstance.preview(obj.path_uri_sim, "sim");
    });
    $("#data_table tbody").on("click", "img.exp", function () {
      let obj = dataTable.row($(this).parents("tr")).data();
      componentInstance.preview(obj.path_uri_expire, "expire");
    });
    $("#data_table tbody").on("click", "img.prev_premi", function () {
      let obj = dataTable.row($(this).parents("tr")).data();
      componentInstance.preview_premi(
        obj._id,
        obj.nama,
        obj.idKendaraan.no_polisi
      );
    });
    $("#data_table tbody").on("click", "button.status_premi", function () {
      let obj = dataTable.row($(this).parents("tr")).data();
      componentInstance.updatePremi(obj, $(this).parents("tr")[0]._DT_RowIndex);
    });
    $("#data_table_info").appendTo(".info_paging_container");
    $("#data_table_paginate").appendTo(".info_paging_container");

    //create empty row
    // let count_row = dataTable.rows().count();
    // while (count_row === 0 || count_row % 10 !== 0) {
    //     let new_row = {}
    //     let count = 0;
    //     while (count < this.emptyRowComp.length) {
    //         new_row[this.emptyRowComp[count]] = "~";
    //         count = count + 1;
    //     }
    //     dataTable.row.add(new_row);
    //     count_row = count_row + 1;
    // }
    // dataTable.draw();

    this.props.status === "TablePremi" && this.props.dataTable(dataTable);
  }

  render() {
    return (
      <div>
        <table
          id="data_table"
          className="stripe"
          width="100%"
          ref={(el) => (this.el = el)}
        >
          <thead>
            <tr>
              {this.props.status == "TablePerusahaan" ? (
                <>
                  <th>Perusahaan</th>
                  <th>Kota</th>
                  <th></th>
                </>
              ) : this.props.status == "TableAdmin" ? (
                <>
                  <th>Nama</th>
                  <th>Perusahaan</th>
                  <th>E-mail</th>
                  <th>No. HP</th>
                  <th>NIK</th>
                  <th>Atc.</th>
                  <th></th>
                </>
              ) : this.props.status == "TableProduk" ? (
                <>
                  <th>Jenis Produk</th>
                  <th></th>
                </>
              ) : this.props.status == "TableLog" ? (
                <>
                  <th>Tanggal</th>
                  <th>Waktu</th>
                  <th>Nama</th>
                  <th>Aktivitas</th>
                  <th>Keterangan</th>
                </>
              ) : this.props.status == "TableNota" ? (
                <>
                  <th>Transportir</th>
                  <th>Penerima</th>
                  <th>No. Nota</th>
                  <th>No. PO</th>
                  <th>NOPOL</th>
                  <th>Jenis Kendaraan</th>
                  <th>Tanggal</th>
                  <th>Status</th>
                  <th style={{ fontSize: "1rem" }}>Detail</th>
                  <th />
                </>
              ) : this.props.status == "TablePerusahaanPengiriman" ? (
                <>
                  <th>Perusahaan</th>
                  <th>Penanggung Jawab</th>
                  <th>No. HP</th>
                  <th>Alamat</th>
                  <th>Kota</th>
                  <th>Kode Pos</th>
                  <th></th>
                </>
              ) : this.props.status == "TableSupir" ? (
                <>
                  <th>Nama</th>
                  <th>No HP</th>
                  <th>SIM</th>
                  <th>NIK</th>
                  <th>No Armada</th>
                  <th>Atc. SIM</th>
                  <th>Atc. NIK</th>
                  <th>Premi</th>
                  {this.props.level === "2" && <th></th>}
                </>
              ) : this.props.status == "TableKendaraan" ? (
                <>
                  <th>Nama Armada</th>
                  <th>Kapasitas (Liter)</th>
                  <th>No Armada</th>
                  <th>Jenis Kendaraan</th>
                  <th>Tanggal Berlaku</th>
                  <th>Nama Perusahaan</th>
                  <th>Atc.</th>
                  <th></th>
                </>
              ) : this.props.status == "TablePenerima" ? (
                <>
                  <th>Perusahaan Penerima</th>
                  <th>Nama</th>
                  <th>No HP</th>
                  <th style={{ width: 200 }}>Alamat</th>
                  <th>Kota</th>
                  <th style={{ width: 70 }}>Kode Pos</th>
                  <th></th>
                </>
              ) : this.props.status == "TablePengambilanProduk" ? (
                <>
                  <th>Titik Pengambilan Barang</th>
                  <th></th>
                </>
              ) : this.props.status == "TablePengirimanKota" ? (
                <>
                  <th>Pengirim</th>
                  <th>Penerima</th>
                  <th>No. Nota</th>
                  <th>No. PO</th>
                  <th>Tanggal</th>
                  <th></th>
                </>
              ) : this.props.status == "TableAdminKota" ? (
                <>
                  <th>Nama</th>
                  <th>E-mail</th>
                  <th>No. HP</th>
                  <th>NIK</th>
                  <th>Atc. KTP</th>
                  <th></th>
                </>
              ) : this.props.status == "TableLogKota" ? (
                <>
                  <th>Tanggal</th>
                  <th>Waktu</th>
                  <th>Nama</th>
                  <th>Aktivitas</th>
                </>
              ) : (
                this.props.status == "TablePremi" && (
                  <>
                    <th>Tanggal Pengiriman</th>
                    <th>No Polisi Kendaraan</th>
                    <th>Tujuan Pengiriman</th>
                    <th>
                      Total Harga <br /> (Rupiah)
                    </th>
                    <th>
                      Biaya Premi <br /> (Rupiah){" "}
                    </th>
                    <th>Status Premi</th>
                  </>
                )
              )}
            </tr>
          </thead>
        </table>
        <div id="contPagi" className="container-pagination">
          <div className="dataTables_wrapper table_footer">
            <div className="info_paging_container"></div>
          </div>
        </div>
        {this.props.status === "TablePerusahaan" && (
          <div className="btn-table-container">
            <div id="btn-container">
              <Button
                id="btn-tambah"
                onClick={() => this.props.history.push("/FormPerusahaan")}
                variant="primary"
              >
                Tambah
              </Button>
            </div>
          </div>
        )}
        {this.props.status === "TableAdmin" && (
          <div className="btn-table-container">
            <div id="btn-container">
              <Button
                id="btn-tambah"
                onClick={() => this.props.history.push("/FormAdmin")}
                variant="primary"
              >
                Tambah
              </Button>
            </div>
          </div>
        )}
        {this.props.status === "TableProduk" && (
          <div className="btn-table-container">
            <div id="btn-container">
              <Button
                id="btn-tambah"
                onClick={() => this.props.history.push("/FormProduk")}
                variant="primary"
              >
                Tambah
              </Button>
            </div>
          </div>
        )}
        {this.props.status === "TablePerusahaanPengiriman" && (
          <div className="btn-table-container">
            <div id="btn-container">
              <Button
                id="btn-tambah"
                onClick={() =>
                  this.props.history.push("/FormPerusahaanPengiriman")
                }
                variant="primary"
              >
                Tambah
              </Button>
            </div>
          </div>
        )}
        {this.props.status === "TableSupir" && this.props.level === "2" && (
          <div className="btn-table-container">
            <div id="btn-container">
              <Button
                id="btn-tambah"
                onClick={() => this.props.history.push("/FormSupir")}
                variant="primary"
              >
                Tambah
              </Button>
            </div>
          </div>
        )}
        {this.props.status === "TableKendaraan" && (
          <div className="btn-table-container">
            <div id="btn-container">
              <Button
                id="btn-tambah"
                onClick={() => this.props.history.push("/FormKendaraan")}
                variant="primary"
              >
                Tambah
              </Button>
            </div>
          </div>
        )}
        {this.props.status === "TablePenerima" && (
          <div className="btn-table-container">
            <div id="btn-container">
              <Button
                id="btn-tambah"
                onClick={() => this.props.history.push("/FormPenerima")}
                variant="primary"
              >
                Tambah
              </Button>
            </div>
          </div>
        )}
        {this.props.status === "TablePengambilanProduk" && (
          <div className="btn-table-container">
            <div id="btn-container">
              <Button
                id="btn-tambah"
                onClick={() =>
                  this.props.history.push("/FormPengambilanProduk")
                }
                variant="primary"
              >
                Tambah
              </Button>
            </div>
          </div>
        )}
        {this.props.status === "TableAdminKota" && (
          <div className="btn-table-container">
            <div id="btn-container">
              <Button
                id="btn-tambah"
                onClick={() => this.props.history.push("/FormAdminKota")}
                variant="primary"
              >
                Tambah
              </Button>
            </div>
          </div>
        )}
        {this.props.status === "TablePremi" && (
          <div className="btn-table-container">
            <div id="btn-container">
              <Button
                id="btn-tambah"
                variant="primary"
                onClick={this.printPremi}
              >
                Print Premi
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

TabelCom.contextType = AuthContext;
export default TabelCom;
