import { Button, Form, Col, Row, Image, Spinner} from "react-bootstrap";
import React, { Component, useState, useEffect } from "react";
import '../styles/Colour_Style.css'
import '../styles/Form_Style.css'
import {editTitikPengambilan, submitTitikPengambilan} from "../logics/SubmitForm_Logic"
import {useForm, ErrorMessage} from "react-hook-form"
import {useAuth} from "../context/Auth_Context"
import {handleTextInput, condensingWhitespace} from "../logics/FormInput_Logic";

function FormPengambilanProdukFunCom(props){
    const {register, handleSubmit, errors, setValue, getValues} = useForm({
        mode:"onChange",
        defaultValues: {
            pengambilan_barang: props.location.state ? props.location.state.nama : "",
            id: props.location.state ? props.location.state._id : "",
            state: props.location.state ? "edit" : "input"
        }
    });
    const {auth,setToken} = useAuth();
    const [loading,setLoading] = useState(false);

    const handleChange = (event) => {
        let val = handleTextInput(event.target.name,event);
        setValue(event.target.name,val);
    }

    const onSubmit = async value => {
        setLoading(true);
        value.idPerusahaan = auth.idPerusahaan;
        let success = false;
        let status = [];
        let message = [];
        value = condensingWhitespace(value)
        let response = getValues("state") === "edit" ? await editTitikPengambilan(value,auth.token,getValues("id")) : await submitTitikPengambilan(value,auth.token);
        if(response[0] && response[0].status===200){
            response[0].token && setToken(response[0].token)
            success = true;
        }  else if (response[0] && response[0].status === 402) {
            let path = "/";
            this.props.history.push({
                pathname: path,
                state: "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi."
            })
        } else{
            if(response[1]) {
                response[1].error.map((item)=>{
                    status = status.concat(1);
                    message = message.concat("Status: "+response[1].status+" - "+response[1].message + ". " + item.msg);
                })
            }
            else {
                status = status.concat(1);
                message = message.concat("Status: "+response[0].status+" - "+response[0].message + ". " + response[0].error[0].msg);
            }
        }
        setLoading(false);
        if(success) {
            props.showModal(value.pengambilan_barang);
        } else {
            let idx = 0;
            status.map(function(item){
                props.generateToast(item,message[idx]);
                idx = idx + 1;
            })
        }
    }

    return(
        <div className="container">
            {getValues("state") === "edit" && <h4>Sedang mengedit Titik Pengambilan: {props.location.state.nama}</h4>} 
            <Form className="form" onSubmit={handleSubmit(onSubmit)}>
                <Form.Group controlId="formBasicEmail">
                    <div>
                        <div className="btn-text">
                            <Form.Label >Titik Pengambilan Barang :</Form.Label>
                        </div>
                        <div className="btn-input">
                            <Form.Control 
                                type="text"
                                name="pengambilan_barang"
                                onChange={handleChange}
                                className={errors.pengambilan_barang && "invalid"}
                                ref={register({required: "Input tidak boleh kosong"})}
                                />
                        </div>
                        <ErrorMessage errors={errors} name="pengambilan_barang">
                            {({ message }) => <p className="error_info">{message}</p>}
                        </ErrorMessage>
                    </div>                                
                </Form.Group>                
                <div className="btn-container">
                    <Button variant="primary" type="submit" disabled={loading}>
                        {loading ? <Spinner animation="border" variant="secondary"/> : "Save"}
                    </Button>
                </div>
            </Form>
        </div>
    )
}

class FormPengambilanProdukCom extends Component{
    constructor(props){
        super(props);
    }

    
    render(){return <FormPengambilanProdukFunCom {...this.props}/>}
}

export default FormPengambilanProdukCom;
