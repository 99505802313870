import React, { Component } from "react";
import SideBar from "../components/SideBar_Com";
import FormPengirimanNotaCom from "../components/FormPengirimanNota_Com";

class FormPengirimanNota extends Component {
    render() {
        return (
            <div className="home">
                <SideBar/>
                <div className="main">
                    <FormPengirimanNotaCom/>
                </div>
            </div>
        );
    }
}

export default FormPengirimanNota;