import Axios from 'axios';
import { getDateTime } from "./CreateDateTime_Logic";
import { createLog } from "./SubmitForm_Logic";

// const BASE_URL = "https://morning-bastion-23858.herokuapp.com/";
// const LOGIN = "auth";
// const PERUSAHAAN_MASTER = "perusahaanMaster";
// const PERUSAHAAN_PENGIRIM = "perusahaanPengirim";
// const ADMIN = "admin";
// const PRODUK = "produk";
// const SUPIR = "supir";
// const KENDARAAN = "kendaraan";
// const PENERIMA = "costumer";
// const TITIK_PENGAMBILAN = "portal";
// const NOTA = "upload/delete"

const handleError = (error) => {
    let errorResponse = null;
    if (error.code === 'ECONNABORTED') {
        errorResponse = {
            status: 408,
            error: [{ msg: error.message }],
            message: "Request Time Out"
        }
    } else if (error.response) {
        errorResponse = error.response.data
    } else { //bisa karena gada error response, etc
        errorResponse = {
            status: 501,
            error: [{ msg: "Server Implementation error." }]
        }
    }
    return errorResponse;
}

const verifyLog = async (responseData,data) => {
    if (responseData !== undefined && responseData.status === 200) {
        await createLog({
            aktivitas: data.aktivitas,
            keterangan: data.keterangan
        }, responseData.token);
    }
}

export const deletePerusahaan = async (token, id, nama) => {
    var responseData;
    var errorResponse = null;
    await Axios({
        method: "DELETE",
        url: process.env.REACT_APP_PERUSAHAAN_MASTER + "/" + id,
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        },
        timeout: 30000,
        timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
    }).then(function (response) {
        responseData = response.data;
    }).catch(function (error) {
        errorResponse = handleError(error);
    });
    let resp = {
        aktivitas: "Delete Data",
        keterangan: "Menghapus data Perusahaan Cabang: "+nama
    }
    await verifyLog(responseData,resp);
    return [responseData, errorResponse];
}

export const deletePerusahaanPengirim = async (token, id, nama) => {
    var responseData;
    var errorResponse = null;
    await Axios({
        method: "DELETE",
        url: process.env.REACT_APP_PERUSAHAAN_PENGIRIM + "/" + id,
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        },
        timeout: 30000,
        timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
    }).then(function (response) {
        responseData = response.data;
    }).catch(function (error) {
        errorResponse = handleError(error);
    });
    let resp = {
        aktivitas: "Delete Data",
        keterangan: "Menghapus data Perusahaan Cabang: "+nama
    }
    await verifyLog(responseData,resp);
    return [responseData, errorResponse];
}

export const deleteAdmin = async (token, id, type, nama) => {
    var responseData;
    var errorResponse = null;
    await Axios({
        method: "DELETE",
        url: process.env.REACT_APP_ADMIN + "/" + id,
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        },
        timeout: 30000,
        timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
    }).then(function (response) {
        responseData = response.data;
    }).catch(function (error) {
        errorResponse = handleError(error);
    });
    let resp = {
        aktivitas: "Delete Data",
        keterangan: "Menghapus data Admin "+type+": "+nama
    }
    await verifyLog(responseData,resp);
    return [responseData, errorResponse];
}

export const deleteProduk = async (token, id, nama) => {
    var responseData;
    var errorResponse = null;
    await Axios({
        method: "DELETE",
        url: process.env.REACT_APP_PRODUK + "/" + id,
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        },
        timeout: 30000,
        timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
    }).then(function (response) {
        responseData = response.data;
    }).catch(function (error) {
        errorResponse = handleError(error);
    });
    let resp = {
        aktivitas: "Delete Data",
        keterangan: "Menghapus data Produk: "+nama
    }
    await verifyLog(responseData,resp);
    return [responseData, errorResponse];
}

export const deleteSupir = async (token, id, nama) => {
    var responseData;
    var errorResponse = null;
    await Axios({
        method: "DELETE",
        url: process.env.REACT_APP_SUPIR + "/" + id,
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        },
        timeout: 30000,
        timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
    }).then(function (response) {
        responseData = response.data;
    }).catch(function (error) {
        errorResponse = handleError(error);
    });
    let resp = {
        aktivitas: "Delete Data",
        keterangan: "Menghapus data Supir: "+nama
    }
    await verifyLog(responseData,resp);
    return [responseData, errorResponse];
}

export const deleteKendaraan = async (token, id, nama) => {
    var responseData;
    var errorResponse = null;
    await Axios({
        method: "DELETE",
        url: process.env.REACT_APP_KENDARAAN + "/" + id,
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        },
        timeout: 30000,
        timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
    }).then(function (response) {
        responseData = response.data;
    }).catch(function (error) {
        errorResponse = handleError(error);
    });
    let resp = {
        aktivitas: "Delete Data",
        keterangan: "Menghapus data Kendaraan: "+nama
    }
    await verifyLog(responseData,resp);
    return [responseData, errorResponse];
}

export const deletePenerima = async (token, id, nama) => {
    var responseData;
    var errorResponse = null;
    await Axios({
        method: "DELETE",
        url: process.env.REACT_APP_PENERIMA + "/" + id,
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        },
        timeout: 30000,
        timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
    }).then(function (response) {
        responseData = response.data;
    }).catch(function (error) {
        errorResponse = handleError(error);
    });
    let resp = {
        aktivitas: "Delete Data",
        keterangan: "Menghapus data Perusahaan Penerima: "+nama
    }
    await verifyLog(responseData,resp);
    return [responseData, errorResponse];
}

export const deleteTitikPengambilan = async (token, id, nama) => {
    var responseData;
    var errorResponse = null;
    await Axios({
        method: "DELETE",
        url: process.env.REACT_APP_TITIK_PENGAMBILAN + "/" + id,
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        },
        timeout: 30000,
        timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
    }).then(function (response) {
        responseData = response.data;
    }).catch(function (error) {
        errorResponse = handleError(error);
    });
    let resp = {
        aktivitas: "Delete Data",
        keterangan: "Menghapus data Portal: "+nama
    }
    await verifyLog(responseData,resp);
    return [responseData, errorResponse];
}

export const deleteGambar = async (token, url) => {
    var responseData;
    var errorResponse = null;
    await Axios({
        method: "DELETE",
        url: process.env.REACT_APP_NOTA_DEL,
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        },
        data: {
            "url": url
        },
        timeout: 30000,
        timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
    }).then(function (response) {
        responseData = response.data;
    }).catch(function (error) {
        errorResponse = handleError(error);
    });
    return [responseData, errorResponse];
}

export const deleteNota = async (token, id, no) => {
    var responseData;
    var errorResponse = null;
    await Axios({
        method: "DELETE",
        url: process.env.REACT_APP_NOTA + "/delete/" + id,
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        },
        timeout: 30000,
        timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
    }).then(function (response) {
        responseData = response.data;
    }).catch(function (error) {
        errorResponse = handleError(error);
    });
    let resp = {
        aktivitas: "Delete Data",
        keterangan: "Menghapus data Nota No. "+no
    }
    await verifyLog(responseData,resp);
    return [responseData, errorResponse];
}