import React, {useState} from 'react';
import './App.css';
import './styles/Colour_Style.css';
import { BrowserRouter as Router,  Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import LoginForm from "./pages/LoginForm_Page";
import GantiPassword from "./pages/GantiPassword_Page";
import FormDataPremi from "./pages/FormDataPremi_Page";

/*Bagian Admin Master Kota*/
import FormSupir from "./pages/FormSupir_Page";
import FormKendaraan from "./pages/FormKendaraan_Page";
import FormPenerima from "./pages/FormPenerima_Page";
import FormPengambilanProduk from "./pages/FormPengambilanProduk_Page";
import FormPengirimanKota from "./pages/FormPengirimanKota_Page";
import FormAdminKota from "./pages/FormAdminKota_Page";
import TableSupir from "./pages/TableSupir_Page";
import TableKendaraan from "./pages/TableKendaraan_Page";
import TablePenerima from "./pages/TablePenerima_Page";
import TablePengambilanProduk from "./pages/TablePengambilanProduk_Page";
import TablePengirimanKota from "./pages/TablePengirimanKota_Page";
import TableAdminKota from "./pages/TableAdminKota_Page";
import TableLogKota from "./pages/TableLogKota_Page";

/*Bagian Grand Master*/
import FormPerusahaan from "./pages/FormPerusahaan_Page";
import FormAdmin from "./pages/FormAdmin_Page";
import FormProduk from "./pages/FormProduk_Page";
import FormPengiriman from "./pages/FormPengiriman_Page";
import FormPerusahaanPengiriman from "./pages/FormPerusahaanPengiriman_Page";
import TablePerusahaan from "./pages/TablePerusahaan_Page";
import TableAdmin from "./pages/TableAdmin_Page";
import TableProduk from "./pages/TableProduk_Page";
import TablePerusahaanPengiriman from "./pages/TablePerusahaanPengiriman_Page";
import TablePengiriman from "./pages/TablePengiriman_Page";
import TableLog from "./pages/TableLog_Page";
import TablePremi from "./pages/TablePremi_Page";
import { AuthContext } from "./context/Auth_Context";

/*Bagian Admin Nota*/
import FormNota from "./pages/FormNota_Page";
import FormPengirimanNota from "./pages/FormPengirimanNota_Page";

/*Unathorized*/
import Unauthorized from "./pages/Unauthorized_Page"
import ServerError from "./pages/ServerError_Page"
import BadRequest from "./pages/BadRequest_Page"

import 'bootstrap/dist/css/bootstrap.min.css'; //lagi make ini biar keliatan modalnya hehe, klo gamau ntr tinggal hapus
import FormNotaEdit from './pages/FormNotaEdit_Page';
import TableRekapan from './pages/TableRekapan_Page';

function App() {
  const [auth, setAuth] = useState({
    token: localStorage.tokens || '',
    level: localStorage.level || '',
    idPerusahaan: localStorage.idPerusahaan || '',
    perusahaan: localStorage.perusahaan || '',
    logo: localStorage.logo || '',
  });

  const setAuthData = data => {
    localStorage.setItem("tokens",data.token)
    localStorage.setItem("level",data.level+"")
    localStorage.setItem("idPerusahaan",data.idPerusahaan)
    localStorage.setItem("perusahaan",data.perusahaan)
    localStorage.setItem("logo",data.logo)
    setAuth({
      token: data.token,
      level: data.level.toString(),
      idPerusahaan: data.idPerusahaan,
      perusahaan: data.perusahaan,
      logo: data.logo
    });
  }

  const setToken = token => {
    localStorage.setItem("tokens",token);
    setAuth({
      ...auth,
      token: token
    })
  }

  return (
    // <LevelContext.Provider value={{ level, setLevel: setLevelAdmin}}>
    <AuthContext.Provider value={{ auth, setAuth: setAuthData, setToken: setToken}}>
    <Router>
      <Switch>
        <Route exact path="/" component={LoginForm}/>
        <PrivateRoute exact path="/TablePremi" component={TablePremi} access={["2","1"]}/>
        <PrivateRoute exact path="/TableLog" component={TableLog} access={["3","2"]}/>
        <PrivateRoute exact path="/GantiPassword" component={GantiPassword} access={["3","2","1"]}/>
        <PrivateRoute exact path="/TableSupir" component={TableSupir} access={["2","1"]}/>

        {/* Admin Master Kota */}       
        <PrivateRoute exact path="/FormSupir" component={FormSupir} access="2"/>
        <PrivateRoute exact path="/FormKendaraan" component={FormKendaraan} access="2"/>
        <PrivateRoute exact path="/FormPenerima" component={FormPenerima} access="2"/>
        <PrivateRoute exact path="/FormPengambilanProduk" component={FormPengambilanProduk} access="2"/>
        <PrivateRoute exact path="/FormPengirimanKota" component={FormPengiriman} access="2"/>
        <PrivateRoute exact path="/FormAdminKota" component={FormAdminKota} access="2"/>
        <PrivateRoute exact path="/TableKendaraan" component={TableKendaraan} access="2"/>
        <PrivateRoute exact path="/TablePenerima" component={TablePenerima} access="2"/>
        <PrivateRoute exact path="/TablePengambilanProduk" component={TablePengambilanProduk} access="2"/>
        <PrivateRoute exact path="/TablePengirimanKota"status="Kota" component={TablePengiriman} access="2"/>
        <PrivateRoute exact path="/TableAdminKota" component={TableAdminKota} access="2"/>
        <PrivateRoute exact path="/FormPerusahaanPengiriman" component={FormPerusahaanPengiriman} access="2"/>
        <PrivateRoute exact path="/TablePerusahaanPengiriman" component={TablePerusahaanPengiriman} access="2"/>

        {/* Grand Master Kota */}
        <PrivateRoute exact path="/FormPerusahaan" component={FormPerusahaan} access="3"/>
        <PrivateRoute exact path="/FormAdmin" component={FormAdmin} access="3"/>
        <PrivateRoute exact path="/FormProduk" component={FormProduk} access="3"/>
        <PrivateRoute exact path="/FormPengiriman" component={FormPengiriman} access="3"/>
        <PrivateRoute exact path="/TablePerusahaan" component={TablePerusahaan} access="3"/>
        <PrivateRoute exact path="/TableAdmin" component={TableAdmin} access="3"/>
        <PrivateRoute exact path="/TableProduk" component={TableProduk} access="3"/>
        <PrivateRoute exact path="/TablePengiriman" component={TablePengiriman} access="3"/>

        {/* Admin Kota Nota */}
        <PrivateRoute exact path="/FormNota" component={FormNota} access="1"/>
        <PrivateRoute exact path="/FormNotaEdit" component={FormNotaEdit} access="1"/>
        <PrivateRoute exact path="/TablePengirimanPerusahaan" component={TablePengiriman} status="Perusahaan" access="1"/>
        <PrivateRoute exact path="/FormPengirimanPerusahaan" component={FormPengiriman} access="1"/>
        <PrivateRoute exact path="/TableRekapan" component={TableRekapan} access="1"/>
        
        <Route exact path="/500ServerError" component={ServerError}/> 
        <Route exact path="/UnknownLevelAccess" component={Unauthorized}/>
        <Route path='*' exact={true} component={BadRequest} /> 
        {/* Unathorized */}
        {/* <Route exact path="/401Unauthorized" component={Unauthorized}/>*/}
      </Switch>
    </Router>
    </AuthContext.Provider>
    // </LevelContext.Provider>
  );
}

export default App;