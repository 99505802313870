import {Form, Col, Row, Button, Image} from "react-bootstrap";
import React, { Component } from "react";
import '../styles/Colour_Style.css'
import '../styles/Form_Style.css'
class FormPengirimanKotaCom extends Component{
    render(){
        return(
            <div className="container">
            {this.props.data === 0 ? <h4>Anda tidak memilih data yang ingin ditampilkan ketika mengakses halaman ini.</h4> : 
                (this.props.data.status === 1 ? <h4>Status Pengiriman: sedang dalam perjalanan</h4> : <h4>Status Pengiriman: selesai</h4>)
            }
            {this.props.data !== 0 &&
                <Row id="row-pengiriman">
                    <div className="logo-container-pengiriman">
                        <Row> 
                            <Col sm={8} style={{display:"grid", alignItems:"center"}}>
                                <h3>PT.MIGAS KUKAR MANDIRI</h3>
                                <h5>Detail Pengiriman</h5>
                            </Col>
                            <Col style={{display:"flex", alignItems:"center", justifyContent:"flex-end"}} sm={4}>
                                <Image className="logo-mkm-pengiriman" src="assets/logo/mkm-transparent.png"/>                       
                            </Col>
                        </Row>
                    </div>
                    <Form className="form-pengiriman">
                        <Row>
                            <Col xs={12} md={8}>
                                <Form.Group as={Row}>
                                        <Form.Label column sm={4} className="label-pengiriman" >Perusahaan Pemilik Kendaraan </Form.Label>
                                        <Col sm={4}>
                                            <Form.Control  plaintext readOnly defaultValue={": "+this.props.data.perusahaan_pemilik_kendaraan}/>                                                                
                                        </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                        <Form.Label column sm={4} className="label-pengiriman" >Nama Supir </Form.Label>
                                        <Col sm={4}>
                                            <Form.Control plaintext readOnly defaultValue={": "+this.props.data.nama_supir} />
                                        </Col>
                                </Form.Group>                
                                <Form.Group as={Row}>
                                        <Form.Label column sm={4} className="label-pengiriman" >Jenis Kendaraan </Form.Label>
                                        <Col sm={4}>
                                            <Form.Control  plaintext readOnly defaultValue={": "+this.props.data.nama_kendaraan}/>                                                                  
                                        </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                        <Form.Label column sm={4} className="label-pengiriman" >No Polisi</Form.Label>
                                        <Col sm={4}>
                                            <Form.Control plaintext readOnly defaultValue={": "+this.props.data.no_polisi} />
                                        </Col>
                                </Form.Group>                
                            </Col>
                            <Col xs={6} md={4}>
                                <Form.Group as={Row}>
                                            <Form.Label column sm={5} className="label-pengiriman" >Tanggal Pengiriman</Form.Label>
                                            <Col sm={7}>
                                                <Form.Control plaintext readOnly defaultValue={": "+this.props.data.tanggal_pengirim}/>                            
                                            </Col>                                           
                                </Form.Group>                
                                <Form.Group as={Row}>
                                            <Form.Label column sm={5} className="label-pengiriman" >No PO</Form.Label>
                                            <Col sm={7}>
                                                <Form.Control  plaintext readOnly defaultValue={": "+this.props.data.no_po}/>
                                            </Col>                  
                                </Form.Group>
                                <Form.Group as={Row}>
                                            <Form.Label column sm={5} className="label-pengiriman" >No Nota Pengiriman</Form.Label>
                                            <Col sm={7}>
                                                <Form.Control plaintext readOnly defaultValue={": "+this.props.data.no_nota} />  
                                            </Col>                                
                                </Form.Group>                                                       
                            </Col>
                        </Row>
                        <Row style={{borderTop:"2px solid #f47121", paddingTop:"10px"}}>
                            <Col>
                                <Form.Group>
                                            <Form.Label className="label-pengiriman" >Asal Pengiriman</Form.Label>
                                            <Form.Control  plaintext readOnly defaultValue={this.props.data.asal_pengiriman.nama}/>                 
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                            <Form.Label className="label-pengiriman" >Titik Pengambilan Barang</Form.Label>
                                            <Form.Control plaintext readOnly defaultValue={this.props.data.titik_pengambilan.nama} />                                
                                </Form.Group>                
                            </Col>
                            <Col>
                                <Form.Group>
                                            <Form.Label className="label-pengiriman" >Tujuan Pengiriman</Form.Label>
                                            <Form.Control  plaintext readOnly defaultValue={this.props.data.tujuan_pengiriman.nama_perusahaan}/>               
                                </Form.Group>                               
                            </Col>
                        </Row>
                        <Row style={{marginTop:"10px"}}>
                            <Col className="col-table">
                                <Form.Group>
                                        <Form.Label className="label-pengiriman" >Jenis Produk</Form.Label>
                                        <Form.Control plaintext readOnly defaultValue={this.props.data.jenis_produk.jenis} />                              
                                </Form.Group>
                            </Col>
                            <Col className="col-table">
                                <Form.Group>
                                            <Form.Label className="label-pengiriman" >Jumlah</Form.Label>
                                            <Form.Control  plaintext readOnly defaultValue={this.props.data.kuantitas}/>                  
                                </Form.Group>
                            </Col>
                            <Col className="col-table">
                                <Form.Group>
                                            <Form.Label className="label-pengiriman" >No Segel</Form.Label>
                                            <Row>
                                                <Form.Label column sm={5}>Atas</Form.Label>
                                                <Col>
                                                    <Form.Control plaintext readOnly defaultValue={": " + this.props.data.no_segel_atas} />
                                                </Col>
                                            </Row>
                                            <Row>                              
                                                <Form.Label column sm={5}>Bawah</Form.Label>
                                                <Col>
                                                    <Form.Control  plaintext readOnly defaultValue={": " + this.props.data.no_segel_bawah}/>                              
                                                </Col>
                                            </Row>
                                </Form.Group>                
                            </Col>
                            <Col className="col-table">
                                <Form.Group>
                                        <Form.Label className="label-pengiriman" >Catatan Tambahan</Form.Label>
                                        <Form.Control  plaintext readOnly defaultValue={this.props.data.catatan}/>                  
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group>
                                    <Form.Label className="label-pengiriman" >Biaya Portal / Penyeberangan</Form.Label>
                                    <Form.Control plaintext readOnly defaultValue={this.props.data.biaya_portal || 0} />                               
                        </Form.Group>                
                                        
                        <Form.Group>
                                    <Form.Label className="label-pengiriman" >Keterangan  Spesial <em> (Opsional)</em></Form.Label>                           
                                    <Form.Control readOnly={this.props.location.state.level !== 1 || this.props.data.status!==1 || this.state.success} type="text" onChange={this.handleChange} defaultValue={this.props.data.keterangan}/>                  
                        </Form.Group>
                        {this.props.location.state.level === 1 && this.props.data.status === 1 && !this.state.success && <Form.Group>
                            <div>
                                <Button disabled={this.state.loading} onClick={this.submit}>Save</Button>
                            </div>                   
                        </Form.Group>}
                        <Form.Group>
                            <div style={{textAlign:"right"}}>
                                <Button onClick={this.print}>Print</Button>
                            </div>                   
                        </Form.Group>
                    </Form>
                </Row>
                }
        </div>
        )
    }
}

export default FormPengirimanKotaCom;
