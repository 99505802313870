export const formatNumber = (number) => {
    if(number == undefined) return 0
    let strRep = number.toString();
    let index = strRep.length;
    let remainder = index%3
    let total = Math.floor(index/3)
    if(total === 0) return number;
    if(remainder === 0) {
        remainder = 3;
        total = total-1;
    }
    let head = strRep.slice(0,remainder);
    let rest = []
    while(total > 0){
        rest.push(strRep.slice(remainder,remainder+3))
        remainder=remainder+3
        total = total-1
    }
    let result = head;
    rest.forEach(function(item){
        result = result + "." +item;
    })
    return result;
}